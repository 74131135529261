@import '../../styles/colors.css';
@import '../../styles/fonts.css';

.container {
  display: grid;
  grid-template-areas:
    "header header"
    "sidebar content"
    "footer footer";
  grid-template-columns: fit-content(100%);
  padding-top: 1em;
  font-family: var(--Acumin)
}

.header {
  grid-area: header;
  font-family: var(--Acumin)
}

.sidebar {
  width: 300px;
  grid-area: sidebar;
  height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
  position: sticky;
  top: 5.5rem;
  left: 0;
  font-family: var(--monstserrat)
}

.content {
  margin-top: 60px;
  grid-area: content;
  height: calc(100vh - 160px);
  overflow-y: auto;
  font-family: var(--Acumin)
}

.footer {
  grid-area: footer;
  font-family: var(--Acumin)
}

.account {
  color: var(--color-header-text);
  font-family: var(--Acumin)
}

@media only screen and (max-width: 600px) {
  .sidebar {
    width: auto;
    height: 100vh;
    margin-top: 30px;
  }
}