@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700&display=swap);
:root {
  --color-header-background: #00a6e9;
  --color-header-text: #fff;
  --color-footer-text: #000;

}
@charset "UTF-8";

:root {
  --roboto: "Roboto", sans-serif;
  --Acumin: "ACUMIN PRO CONDENSED", sans-serif;
  --inter: "Inter", sans-serif;
  --plex: "IBM Plex Sans", sans-serif;
  --lato: "Lato", sans-serif;
  --manrope: "Manrope", sans-serif;
  --monstserrat: "MONTSERRAT", sans-serif;

  /*Font sizes*/
  --font_size_h1: 6rem;
  --font_size_h2: 3.75rem;
  --font_size_h3: 3rem;
  --font_size_h4: 2.125rem;
  --font_size_h5: 1.5rem;
  --font_size_h6: 1.25rem;
  --font_size_body: 1rem;
  --font_size_body_large: 1.25rem;
  --font_size_body_small: 0.875rem;
  --font_size_button: 0.875rem;
  --font_size_caption: 0.75rem;
  --font_size_caption_small: 0.625rem;
  --font_size_overline: 0.75rem;
  --font_size_overline_small: 0.625rem;

  /*Font weight*/
  --font_weight_h1: 700;
  --font_weight_h2: 700;
  --font_weight_h3: 700;
  --font_weight_h4: 700;
  --font_weight_h5: 700;
  --font_weight_h6: 700;
  --font_weight_body: 400;
  --font_weight_body_medium: 500;
  --font_weight_button: 700;
  --font_weight_caption: 400;
  --font_weight_caption_small: 500;
  --font_weight_overline: 600;

  /*Letter spacing*/
  --letter_spacing_h1: -0.026em;
  --letter_spacing_h2: -0.025em;
  --letter_spacing_h3: -0.01em;
  --letter_spacing_h4: 0;
  --letter_spacing_h5: 0;
  --letter_spacing_h6: 0.0075em;
  --letter_spacing_body: 0.0275em;
  --letter_spacing_body_bold: 0.022em;
  --letter_spacing_body_large: 0.0075em;
  --letter_spacing_body_large_bold: 0.004em;
  --letter_spacing_body_small: 0.018em;
  --letter_spacing_button: 0.054em;
  --letter_spacing_caption: 0.033em;
  --letter_spacing_caption_small: 0.06em;
  --letter_spacing_overline: 0.0168em;
  --letter_spacing_overline_small: 0.15em;
}

.fonts_roboto__13o6R {
  font-family: "Roboto", sans-serif;
  font-family: var(--roboto);
}

.fonts_quicksand__1XQZO {
  font-family: var(--quicksand);
}

.fonts_inter__3niDE {
  font-family: "Inter", sans-serif;
  font-family: var(--inter);
}

.fonts_lato__3O3sM {
  font-family: "Lato", sans-serif;
  font-family: var(--lato);
}

.fonts_plex__Wmm4E {
  font-family: "IBM Plex Sans", sans-serif;
  font-family: var(--plex);
}

.fonts_monrope__geBAe {
  font-family: "Manrope", sans-serif;
  font-family: var(--manrope);
}

.fonts_h1__3xeCv,
.fonts_H1__1XKNv {
  font-weight: 700;
  font-weight: var(--font_weight_h1);
  font-size: 6rem;
  font-size: var(--font_size_h1);
  letter-spacing: -0.026em;
  letter-spacing: var(--letter_spacing_h1);
}

.fonts_h2__1-TRt,
.fonts_H2__1QHnK {
  font-weight: 700;
  font-weight: var(--font_weight_h2);
  font-size: 3.75rem;
  font-size: var(--font_size_h2);
  letter-spacing: -0.025em;
  letter-spacing: var(--letter_spacing_h2);
}

.fonts_h3__1S6_f,
.fonts_H3__1BaL0 {
  font-weight: 700;
  font-weight: var(--font_weight_h3);
  font-size: 3rem;
  font-size: var(--font_size_h3);
  letter-spacing: -0.01em;
  letter-spacing: var(--letter_spacing_h3);
}

.fonts_h4__2sVRl,
.fonts_H4__gP8YW {
  font-weight: 700;
  font-weight: var(--font_weight_h4);
  font-size: 2.125rem;
  font-size: var(--font_size_h4);
  letter-spacing: 0;
  letter-spacing: var(--letter_spacing_h4);
}

.fonts_h5__3BR_f,
.fonts_H5__1sHZ9 {
  font-weight: 700;
  font-weight: var(--font_weight_h5);
  font-size: 1.5rem;
  font-size: var(--font_size_h5);
  letter-spacing: 0;
  letter-spacing: var(--letter_spacing_h5);
}

.fonts_h6__1sX-5,
.fonts_H6__xEvch {
  font-weight: 700;
  font-weight: var(--font_weight_h6);
  font-size: 1.25rem;
  font-size: var(--font_size_h6);
  letter-spacing: 0.0075em;
  letter-spacing: var(--letter_spacing_h6);
}

.fonts_body__348R5,
.fonts_BodyBig__2zyze {
  font-weight: 400;
  font-weight: var(--font_weight_body);
  font-size: 1rem;
  font-size: var(--font_size_body);
  letter-spacing: 0.0275em;
  letter-spacing: var(--letter_spacing_body);
}

.fonts_button_text__2dPTW {
  font-weight: 700;
  font-weight: var(--font_weight_button);
  font-size: 0.875rem;
  font-size: var(--font_size_button);
  letter-spacing: 0.054em;
  letter-spacing: var(--letter_spacing_button);
}

.fonts_caption__sx9fE {
  font-weight: 400;
  font-weight: var(--font_weight_caption);
  font-size: 0.75rem;
  font-size: var(--font_size_caption);
  letter-spacing: 0.033em;
  letter-spacing: var(--letter_spacing_caption);
}

.fonts_overline__2lA9e {
  font-weight: 600;
  font-weight: var(--font_weight_overline);
  font-size: 0.75rem;
  font-size: var(--font_size_overline);
  letter-spacing: 0.0168em;
  letter-spacing: var(--letter_spacing_overline);
  text-transform: uppercase;
}
.style_container__11cjs {
  padding-right: 1rem;
  padding-left: 1rem;
  font-family: var(--Acumin)
}

.style_sub_title__5SDL3 {
  padding-top: 1rem;
}

.style_h4_title__3FGM2 {

  padding-right: 5rem;
  font-family: var(--Acumin);
}

.style_h5_title__3nDOl {

  padding-right: 5rem;
  font-family: var(--Acumin);

}

.style_text__1uh_0 {
  padding-top: 0.5rem;
  padding-right: 5rem;
  font-family: var(--Acumin);
}

.style_link__3ttuF,
.style_link__3ttuF:active {
  color: var(--blue60);
  margin: 0 0.25rem;
  text-decoration: underline;
}

.style_link__3ttuF:hover {
  color: var(--blue70);
}

.style_promo_group__3TGO- {
  display: flex;
  flex-direction: column;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.style_promo_item__wN5Zs {
  width: 100%;
  padding-bottom: 1.5rem;
}

.style_item_img_container__E_Ujb {
  border-radius: 0.5rem;
  padding: 0.25rem;

}

.style_item_logo__3xXbK {
  padding-right: 5rem;
  width: 25%;

}

.style_item_img__14DXg {
  padding-right: 5rem;
  width: 100%;

}

@media screen and (min-width: 640px) {
  .style_promo_group__3TGO- {
    display: flex;
    flex-direction: initial;
    justify-content: space-between;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .style_promo_item__wN5Zs {
    width: calc(25% - 0.75rem);
    padding-bottom: 1.5rem;
  }
}
.style_container__2m_Rg {
  padding-right: 1rem;
  padding-left: 1rem;
  font-family: var(--Acumin)
}

.style_sub_title__3wsvm {
  padding-top: 1rem;
}

.style_h4_title__v5ixK {

  padding-right: 5rem;
  font-family: var(--Acumin);
}

.style_h5_title__DiDRc {

  padding-right: 5rem;
  font-family: var(--Acumin);

}

.style_text__3yVtV {
  padding-top: 0.5rem;
  padding-right: 5rem;
  font-family: var(--Acumin);
}

.style_link__CFa2d,
.style_link__CFa2d:active {
  color: var(--blue60);
  margin: 0 0.25rem;
  text-decoration: underline;
}

.style_link__CFa2d:hover {
  color: var(--blue70);
}

.style_promo_group__VPeVc {
  display: flex;
  flex-direction: column;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.style_promo_item__1nTB5 {
  width: 100%;
  padding-bottom: 1.5rem;
}

.style_item_img_container__1NSax {
  border-radius: 0.5rem;
  padding: 0.25rem;

}

.style_item_logo__31V0p {
  height: 55px;
}

.style_item_img__AWoOk {
  padding-right: 5rem;
  width: 100%;

}

@media screen and (min-width: 640px) {
  .style_promo_group__VPeVc {
    display: flex;
    flex-direction: initial;
    justify-content: space-between;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .style_promo_item__1nTB5 {
    width: calc(25% - 0.75rem);
    padding-bottom: 1.5rem;
  }
}
.style_container__31fjQ {
  padding-right: 1rem;
  padding-left: 1rem;
  font-family: var(--Acumin)
}

.style_sub_title__Vz7M- {
  padding-top: 1rem;
}

.style_h4_title__3IL_- {

  padding-right: 5rem;
  font-family: var(--Acumin);
}

.style_h5_title__3jXeW {

  padding-right: 5rem;
  font-family: var(--Acumin);

}

.style_text__1yEzD {
  padding-top: 0.5rem;
  padding-right: 5rem;
  font-family: var(--Acumin);
}

.style_link__3TBL4,
.style_link__3TBL4:active {
  color: var(--blue60);
  margin: 0 0.25rem;
  text-decoration: underline;
}

.style_link__3TBL4:hover {
  color: var(--blue70);
}

.style_promo_group__1DZfL {
  display: flex;
  flex-direction: column;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.style_promo_item__1mAQI {
  width: 100%;
  padding-bottom: 1.5rem;
}

.style_item_img_container__37NX0 {
  border-radius: 0.5rem;
  padding: 0.25rem;

}

.style_item_logo__OuzJV {
  height: 55px;
}

.style_item_img__3xoTJ {
  padding-right: 5rem;
  width: 100%;

}

@media screen and (min-width: 640px) {
  .style_promo_group__1DZfL {
    display: flex;
    flex-direction: initial;
    justify-content: space-between;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .style_promo_item__1mAQI {
    width: calc(25% - 0.75rem);
    padding-bottom: 1.5rem;
  }
}
.style_container__H7AtY {
  padding-right: 1rem;
  padding-left: 1rem;
  font-family: var(--Acumin)
}

.style_sub_title__1mxsY {
  padding-top: 1rem;
}

.style_h4_title__39DEh {

  padding-right: 5rem;
  font-family: var(--Acumin);
}

.style_h5_title__lyAP_ {

  padding-right: 5rem;
  font-family: var(--Acumin);

}

.style_text__3DIJ6 {
  padding-top: 0.5rem;
  padding-right: 5rem;
  font-family: var(--Acumin);
}

.style_link__pa7cb,
.style_link__pa7cb:active {
  color: var(--blue60);
  margin: 0 0.25rem;
  text-decoration: underline;
}

.style_link__pa7cb:hover {
  color: var(--blue70);
}

.style_promo_group__3CChl {
  display: flex;
  flex-direction: column;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.style_promo_item__qErGn {
  width: 100%;
  padding-bottom: 1.5rem;
}

.style_item_img_container__2HCk0 {
  border-radius: 0.5rem;
  padding: 0.25rem;

}

.style_item_logo__2cPSj {
  height: 55px;
}

.style_item_img__2bT1F {
  padding-right: 5rem;
  width: 100%;

}

@media screen and (min-width: 640px) {
  .style_promo_group__3CChl {
    display: flex;
    flex-direction: initial;
    justify-content: space-between;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .style_promo_item__qErGn {
    width: calc(25% - 0.75rem);
    padding-bottom: 1.5rem;
  }
}

@media only screen and (max-width: 600px) {
  .style_text__3DIJ6 {
    padding-right: 0;
  }
}
:root{--color-header-background: #00a6e9;--color-header-text: #fff;--color-footer-text: #000}
.container{display:grid;grid-template-areas:"header header " "sidebar content " "footer footer";grid-template-columns:fit-content(100%)}.navbar{grid-area:header;background:var(--color-header-background)}.footer{padding:12px 20px;display:flex;height:10vh;align-items:center;justify-content:space-between;font-weight:700;left:0}.content{top:4.5rem;height:80vh;grid-area:content;margin-right:0;margin-left:0;width:150vh;padding:0 1rem}.left_sidebar{width:180px;grid-area:sidebar;height:80vh;overflow-y:auto;overflow-x:hidden;position:fixed;top:4.5rem;left:0}.sidebar{grid-area:sidebar;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content;width:240px;z-index:5;display:block;-webkit-transform:translateX(-100%);transform:translateX(-100%);transition:all 250ms ease-in-out;display:contents;background:var(--grey0);border-radius:0 .5rem .5rem 0}.root{flex-grow:1}.button{float:"right"}.title{flex-grow:1}.main-content{display:inline-block;width:100%}ul{margin:0;padding:0}li{list-style-type:none;list-style-position:outside}.blink{-webkit-animation:blink-animation 3s steps(25, start) infinite;animation:blink-animation 3s steps(25, start) infinite}button:focus{outline:0}
.App_container__3_TW9 {
  display: grid;
  grid-template-areas:
    "header header"
    "sidebar content"
    "footer footer";
  grid-template-columns: fit-content(100%);
  padding-top: 1em;
  font-family: var(--Acumin)
}

.App_header__20aTX {
  grid-area: header;
  font-family: var(--Acumin)
}

.App_sidebar__3WVHp {
  width: 300px;
  grid-area: sidebar;
  height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
  position: -webkit-sticky;
  position: sticky;
  top: 5.5rem;
  left: 0;
  font-family: var(--monstserrat)
}

.App_content__28BVt {
  margin-top: 60px;
  grid-area: content;
  height: calc(100vh - 160px);
  overflow-y: auto;
  font-family: var(--Acumin)
}

.App_footer__uT5Na {
  grid-area: footer;
  font-family: var(--Acumin)
}

.App_account__3eMX- {
  color: var(--color-header-text);
  font-family: var(--Acumin)
}

@media only screen and (max-width: 600px) {
  .App_sidebar__3WVHp {
    width: auto;
    height: 100vh;
    margin-top: 30px;
  }
}
/* TREE TYPES*/

._type_def__2FFAY li[class*="tree-node"] {
  font-size: var(--font_size_body);
  letter-spacing: var(--letter_spacing_body);
  font-weight: var(--font_weight_body);
  transition: all 100ms ease-in-out;
}
._type_dense__7t8NK li[class*="tree-node"] {
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
  font-weight: var(--font_weight_body);
  transition: all 100ms ease-in-out;
}

._type_def__2FFAY li[class*="tree-node-expanded"],
._type_dense__7t8NK li[class*="tree-node-expanded"] {
  font-weight: var(--font_weight_body_medium);
  transition: all 150ms ease-in;
}

._type_def__2FFAY li[class*="selected"],
._type_dense__7t8NK li[class*="selected"] {
  font-weight: var(--font_weight_body_medium);
  transition: all 150ms ease-in;
}

._type_def__2FFAY li > div[class*="node-content"] {
  min-height: 2.5rem;
  height: unset;
  margin-bottom: 0.25rem;
  box-shadow: none;
  border-radius: 0.25rem;
  transition: all 200ms ease-in-out;
}
._type_dense__7t8NK li > div[class*="node-content"] {
  min-height: 1.75rem;
  height: unset;
  margin-bottom: 0.125rem;
  box-shadow: none;
  border-radius: 0.125rem;
  transition: all 200ms ease-in-out;
}

._type_def__2FFAY
  li
  > div[class*="node-content"]
  > span[class*="node-caret"]:not([class*="node-caret-none"]) {
  width: 2rem;
  height: 2rem;
  min-width: unset;
  margin-left: 0.25rem;
  margin-right: 0.5rem;
  border-radius: 100%;
  padding: 0;
}

._type_dense__7t8NK li > div[class*="node-content"] > span[class*="node-caret"] {
  /*width: 1.25rem;
  height: 1.25rem;*/
  min-width: unset;
  margin-left: 0.375rem;
  margin-right: 0.5rem;
  padding: 0;
}
._type_def__2FFAY li > div[class*="node-content"] > span[class*="node-caret"] > svg,
._type_def__2FFAY span[class*="node-caret-none"] ~ span[class*="node-icon"] > svg {
  width: 1.25rem;
  height: 1.25rem;
  margin: auto;
}
._type_def__2FFAY li > div[class*="node-content"] > span[class*="node-caret"] > svg {
  margin-top: 0.375rem;
}
._type_def__2FFAY span[class*="node-caret-none"] {
  min-width: 0.625rem;
  margin-left: 0.625rem;
}
._type_dense__7t8NK span[class*="node-caret-none"] {
  margin-left: 0 !important;
}

._type_def__2FFAY div[with-secondary-label] {
  height: 2.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
._type_def__2FFAY div[with-secondary-label] > div {
  /* font-size: var(--font-size-Body2);
  letter-spacing: var(--letter-spacing-Body2);*/
  margin-top: 0.125rem;
}

._type_def__2FFAY li[class*="disabled"] > div > span > div[with-secondary-label] > div {
  /* font-size: var(--font-size-Body2);
  letter-spacing: var(--letter-spacing-Body2);*/
}

._type_def__2FFAY
  li[class*="disabled"]
  > div
  > span
  > div[with-secondary-label]
  > div
  > span {
  display: inline-block;
}

/*Secondary icon*/
._type_def__2FFAY span[class*="node-secondary-label"] > span[class*="icon"] > svg {
  width: 1.25rem;
  height: 1.25rem;
}
/*----*/

/*TREE*/

._view_flat__2K2A1 {
  color: var(--color100);
}
._view_flat__2K2A1 span > svg {
  color: var(--color50);
}
._view_flat__2K2A1 li > div:first-of-type:hover {
  box-shadow: var(--shadow2dp);
  transition: all 150ms ease-in;
}
._view_flat__2K2A1 li > div:hover {
  background-color: unset;
}
._view_flat__2K2A1 li[class*="selected"] > div:first-of-type {
  background-color: var(--color5);
  color: var(--color100);
  box-shadow: none;
  transition: all 150ms ease-in;
}
._view_flat__2K2A1 li[class*="selected"] svg {
  color: var(--color70);
  transition: all 150ms ease-in;
}

._view_flat__2K2A1 li > div:first-of-type:hover > span:first-of-type {
  background: var(--color10);
  transition: all 150ms ease-in;
}
._view_flat__2K2A1[class*="dense"] li > div:first-of-type:hover > span:first-of-type {
  background: none;
  transition: all 150ms ease-in;
}
._view_flat__2K2A1
  li:not([class*="disabled"])
  > div:first-of-type:hover
  > span[class*="node-caret"]:first-of-type
  > svg {
  color: var(--color100);
  transition: all 150ms ease-in;
}
._view_flat__2K2A1 li:hover > div:first-of-type > span[class*="none"] {
  background: none;
  transition: all 150ms ease-in;
}
._view_flat__2K2A1 li:hover {
  cursor: pointer;
}
._view_flat__2K2A1 span[class*="node-caret-closed"] > svg {
  color: var(--color40);
  transition: all 150ms ease-in;
}
._view_flat__2K2A1 span[class*="node-caret-open"] > svg {
  color: var(--color70);
  transition: all 150ms ease-in;
}
._view_flat__2K2A1 div[with-secondary-label] > div {
  color: var(--color60);
}

/*DISABLED*/
._view_flat__2K2A1 li[class*="disabled"],
._view_flat__2K2A1 li[class*="disabled"] > div:first-of-type,
._view_flat__2K2A1 li[class*="disabled"] > div:first-of-type:hover > span,
._view_flat__2K2A1 li[class*="disabled"] > div:first-of-type:hover > span > svg,
._view_flat__2K2A1 li[class*="disabled"] > div:first-of-type > span > svg {
  color: var(--color20);
  box-shadow: none;
  background: unset;
}
/*Custom*/
._view_flat__2K2A1 li[class*="disabled"] > div > span > div[with-secondary-label] > div {
  color: var(--yellow70);
}
._view_flat__2K2A1
  li[class*="disabled"]
  > div
  > span
  > div[with-secondary-label]
  > div
  > span
  > svg {
  color: var(--yellow70);
}

/* COLORS*/
:root {
  --color0: var(--grey0);
  --color5: var(--grey5);
  --color10: var(--grey10);
  --color20: var(--grey20);
  --color30: var(--grey30);
  --color40: var(--grey40);
  --color50: var(--grey50);
  --color60: var(--grey60);
  --color70: var(--grey70);
  --color80: var(--grey80);
  --color90: var(--grey90);
  --color100: var(--grey100);

  --shadow2dp: var(--greyShadow2dp);
  --shadow4dp: var(--greyShadow4dp);
  --shadow8dp: var(--greyShadow8dp);
  --shadow16dp: var(--greyShadow16dp);
  --shadow24dp: var(--greyShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradGrey);
  --gradient-reverse: var(--gradGreyRev);
  /*-----------------*/

  /*TABLE*/
  --flat-def-bg-table-hover: var(--color5);
  --flat-def-color-table: var(--color50);
  --flat-def-border-table: var(--color10);
  --flat-def-border2-table: var(--color50);
  --flat-def-borderlast-table: var(--color40);
  --flat-def-color-table-pag: var(--color60);
  --flat-def-color-checkbox: var(--color70);

  --filled-def-bg-table: var(--color40);
  --filled-def-bg-table-hover: var(--color50);
  --filled-def-bg-head-table: var(--color70);
  --filled-def-border-table: var(--color30);
  --filled-def-border2-table: var(--color10);
  --filled-def-head-table-sort: var(--color60);
  --filled-def-color-checkbox: var(--color20);

  --smooth-def-bg-head-table: var(--color10);
  --smooth-def-bg-table: var(--color0);
  --smooth-def-border-table: var(--color10);
  --smooth-def-border2-table: var(--color50);
  --smooth-def-color-table: var(--color50);
  --smooth-def-color-table-pag: var(--color60);

  --raised-def-border-table: var(--color10);
  --raised-def-border2-table: var(--color50);
  --raised-def-prim-color-table: var(--color50);
  /*-------------------*/
}

.color_select__1FXCJ {
  /*TABLE*/
  --flat-bg-table-hover: var(--color5);
  --flat-color-table: var(--color50);
  --flat-border-table: var(--color10);
  --flat-border2-table: var(--color50);
  --flat-borderlast-table: var(--color40);
  --flat-color-table-pag: var(--color60);
  --flat-color-checkbox: var(--color70);

  --filled-bg-table: var(--color40);
  --filled-bg-table-hover: var(--color50);
  --filled-bg-head-table: var(--color70);
  --filled-border-table: var(--color30);
  --filled-border2-table: var(--color10);
  --filled-head-table-sort: var(--color60);
  --filled-color-checkbox: var(--color20);

  --smooth-bg-head-table: var(--color10);
  --smooth-bg-table: var(--color0);
  --smooth-border-table: var(--color10);
  --smooth-border2-table: var(--color50);
  --smooth-color-table: var(--color50);
  --smooth-color-table-pag: var(--color60);

  --raised-border-table: var(--color10);
  --raised-border2-table: var(--color50);
  --raised-prim-color-table: var(--color50);
  /*-------------------*/
}

.color_primary__36oNk {
  --color0: var(--blue0);
  --color5: var(--blue5);
  --color10: var(--blue10);
  --color20: var(--blue20);
  --color30: var(--blue30);
  --color40: var(--blue40);
  --color50: var(--blue50);
  --color60: var(--blue60);
  --color70: var(--blue70);
  --color80: var(--blue80);
  --color90: var(--blue90);
  --color100: var(--blue100);

  --shadow2dp: var(--blueShadow2dp);
  --shadow4dp: var(--blueShadow4dp);
  --shadow8dp: var(--blueShadow8dp);
  --shadow16dp: var(--blueShadow16dp);
  --shadow24dp: var(--blueShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradBlue);
  --gradient-reverse: var(--gradBlueRev);
  /*-----*/
}

.color_danger__35rc6 {
  --color0: var(--red0);
  --color5: var(--red5);
  --color10: var(--red10);
  --color20: var(--red20);
  --color30: var(--red30);
  --color40: var(--red40);
  --color50: var(--red50);
  --color60: var(--red60);
  --color70: var(--red70);
  --color80: var(--red80);
  --color90: var(--red90);
  --color100: var(--red100);

  --shadow2dp: var(--redShadow2dp);
  --shadow4dp: var(--redShadow4dp);
  --shadow8dp: var(--redShadow8dp);
  --shadow16dp: var(--redShadow16dp);
  --shadow24dp: var(--redShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradRed);
  --gradient-reverse: var(--gradRedRev);
  /*-----*/
}

.color_success__bI-Bb {
  --color0: var(--green0);
  --color5: var(--green5);
  --color10: var(--green10);
  --color20: var(--green20);
  --color30: var(--green30);
  --color40: var(--green40);
  --color50: var(--green50);
  --color60: var(--green60);
  --color70: var(--green70);
  --color80: var(--green80);
  --color90: var(--green90);
  --color100: var(--green100);

  --shadow2dp: var(--greenShadow2dp);
  --shadow4dp: var(--greenShadow4dp);
  --shadow8dp: var(--greenShadow8dp);
  --shadow16dp: var(--greenShadow16dp);
  --shadow24dp: var(--greenShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradGreen);
  --gradient-reverse: var(--gradGreenRev);
  /*-----------------*/
}

.color_warning__2UWYL {
  --color0: var(--yellow0);
  --color5: var(--yellow5);
  --color10: var(--yellow10);
  --color20: var(--yellow20);
  --color30: var(--yellow30);
  --color40: var(--yellow40);
  --color50: var(--yellow50);
  --color60: var(--yellow60);
  --color70: var(--yellow70);
  --color80: var(--yellow80);
  --color90: var(--yellow90);
  --color100: var(--yellow100);

  --shadow2dp: var(--yellowShadow2dp);
  --shadow4dp: var(--yellowShadow4dp);
  --shadow8dp: var(--yellowShadow8dp);
  --shadow16dp: var(--yellowShadow16dp);
  --shadow24dp: var(--yellowShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradYellow);
  --gradient-reverse: var(--gradYellowRev);
  /*-----------------*/
}

.color_default__1IouF {
  --color0: var(--grey0);
  --color5: var(--grey5);
  --color10: var(--grey10);
  --color20: var(--grey20);
  --color30: var(--grey30);
  --color40: var(--grey40);
  --color50: var(--grey50);
  --color60: var(--grey60);
  --color70: var(--grey70);
  --color80: var(--grey80);
  --color90: var(--grey90);
  --color100: var(--grey100);

  --shadow2dp: var(--greyShadow2dp);
  --shadow4dp: var(--greyShadow4dp);
  --shadow8dp: var(--greyShadow8dp);
  --shadow16dp: var(--greyShadow16dp);
  --shadow24dp: var(--greyShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradGrey);
  --gradient-reverse: var(--gradGreyRev);
  /*-----------------*/
}

.color_primary_alt__1r3Zv {
  --color0: var(--indigo0);
  --color5: var(--indigo5);
  --color10: var(--indigo10);
  --color20: var(--indigo20);
  --color30: var(--indigo30);
  --color40: var(--indigo40);
  --color50: var(--indigo50);
  --color60: var(--indigo60);
  --color70: var(--indigo70);
  --color80: var(--indigo80);
  --color90: var(--indigo90);
  --color100: var(--indigo100);

  --shadow2dp: var(--indigoShadow2dp);
  --shadow4dp: var(--indigoShadow4dp);
  --shadow8dp: var(--indigoShadow8dp);
  --shadow16dp: var(--indigoShadow16dp);
  --shadow24dp: var(--indigoShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradIndigo);
  --gradient-reverse: var(--gradIndigoRev);
  /*-----*/
}

.color_danger_alt__3mwOq {
  --color0: var(--pink0);
  --color5: var(--pink5);
  --color10: var(--pink10);
  --color20: var(--pink20);
  --color30: var(--pink30);
  --color40: var(--pink40);
  --color50: var(--pink50);
  --color60: var(--pink60);
  --color70: var(--pink70);
  --color80: var(--pink80);
  --color90: var(--pink90);
  --color100: var(--pink100);

  --shadow2dp: var(--pinkShadow2dp);
  --shadow4dp: var(--pinkShadow4dp);
  --shadow8dp: var(--pinkShadow8dp);
  --shadow16dp: var(--pinkShadow16dp);
  --shadow24dp: var(--pinkShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradPink);
  --gradient-reverse: var(--gradPinkRev);
  /*-----*/
}

.color_success_alt__2Lf2R {
  --color0: var(--teal0);
  --color5: var(--teal5);
  --color10: var(--teal10);
  --color20: var(--teal20);
  --color30: var(--teal30);
  --color40: var(--teal40);
  --color50: var(--teal50);
  --color60: var(--teal60);
  --color70: var(--teal70);
  --color80: var(--teal80);
  --color90: var(--teal90);
  --color100: var(--teal100);

  --shadow2dp: var(--tealShadow2dp);
  --shadow4dp: var(--tealShadow4dp);
  --shadow8dp: var(--tealShadow8dp);
  --shadow16dp: var(--tealShadow16dp);
  --shadow24dp: var(--tealShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradTeal);
  --gradient-reverse: var(--gradTealRev);
  /*-----------------*/
}

.color_warning_alt__3waVm {
  --color0: var(--orange0);
  --color5: var(--orange5);
  --color10: var(--orange10);
  --color20: var(--orange20);
  --color30: var(--orange30);
  --color40: var(--orange40);
  --color50: var(--orange50);
  --color60: var(--orange60);
  --color70: var(--orange70);
  --color80: var(--orange80);
  --color90: var(--orange90);
  --color100: var(--orange100);

  --shadow2dp: var(--orangeShadow2dp);
  --shadow4dp: var(--orangeShadow4dp);
  --shadow8dp: var(--orangeShadow8dp);
  --shadow16dp: var(--orangeShadow16dp);
  --shadow24dp: var(--orangeShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradOrange);
  --gradient-reverse: var(--gradOrangeRev);
  /*-----------------*/
}

/* END COLORS*/
/* ICON ANIMATION*/

/*.leftSidebar li[class*="selected"] > div:first-of-type > span[class*="icon"] {
  animation: shake 180ms ease-in;
}
@keyframes shake {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(-1);
  }
}*/

.demo_leftSidebar__3KgjM {
  padding-left: 0;
  padding-right: 0;
  margin-right: 0.75rem;
  margin-left: 0.25rem;
}
.styles_footerBar__fIKP1 {
  padding: 20px 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.styles_text__3ytlE {
  font-weight: 700;
  color: var(--color-footer-text);
}

.styles_contactLinks__VVeyL {
  text-align: right;
  bottom: 10px;
}

.styles_contactLinks__VVeyL>a {
  cursor: pointer;
}

.styles_contactLinks__VVeyL>a+a {
  margin-left: 35px;

}

.styles_contact__3LFi7>p {
  font-weight: 400;
  margin-bottom: 0;
}

.styles_contact__3LFi7>p>a {
  cursor: pointer;
  margin-left: 10px;
  bottom: 0;
}

@media only screen and (max-width: 600px) {
  .styles_footerBar__fIKP1 {
    flex-direction: column;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
  }
  .styles_contactLinks__VVeyL {
    text-align: center;
  }
  .styles_contactLinks__VVeyL + p {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
/* BUTTONS TYPES*/
/*--DEFAULT--*/
._type_default__UXVZj {
  font-size: var(--font_size_button);
  font-family: var(--font_family_button);
  font-weight: bold;
  letter-spacing: var(--letter-spacing-button);
  padding: 0 0.75rem 0 0.75rem;
}

._type_right__1m14B {
  border-radius: var(--s8) 0 0 var(--s8);
}
._type_left__13Gdp {
  border-radius: 0 var(--s8) var(--s8) 0;
}
._type_top__36pXY {
  border-radius: 0 0 var(--s8) var(--s8);
}
._type_bottom__XeUKz {
  border-radius: var(--s8) var(--s8) 0 0;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}

/*SMOOTH STYLE*/
._view_smooth__nSz33 {
  background: var(--white);
  box-shadow: var(--greyShadow8dp);
}

._view_smooth__nSz33 span[class*="icon"] {
  color: var(--color70);
}
/* END STYLE */

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

/*body {
  overflow-y: scroll;
}*/
text {
  font-family: "Roboto";
}

.demo_style_NavBar__3V-MA {
  display: flex;
  border-bottom: 1px solid #333333;
}

.demo_style_NavBar__3V-MA>div {
  padding: 10px;
  cursor: pointer;
}

.demo_style_NavBarContent__2Qpw1 {
  padding: 10px;
}

.demo_style_contact_footer__2xpDq {
  font-weight: 400;
  margin-bottom: 10px;
}

/*MOBILE*/
.demo_style_container__1w8yB {
  height: 100vh;
  display: grid;
  grid-template-areas:
    "header"
    "."
    "content"
    "."
    "footer";
  grid-template-columns: 1fr;
  grid-template-rows: 6rem 1rem auto 1rem 3rem;
}

.demo_style_header__2rMYD {
  background-color: #9c2b23;
  background: #9c2b23;
  color: #9c2b23;
}

.demo_style_sidebar__2T8Ua {
  grid-area: sidebar;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 240px;
  z-index: 5;
  display: block;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  transition: all 250ms ease-in-out;
  display: contents;
  /*overflow-y: auto;*/
  background: var(--grey0);
  border-radius: 0 0.5rem 0.5rem 0;
}

.demo_style_content__17G6x {
  grid-area: content;
  margin-left: 0;
  margin-right: 0;
  overflow-y: unset;
}

.demo_style_content-component__1bkUg {
  display: grid;
  padding-right: 1rem;
  padding-left: 1rem;
  /* padding-bottom: 5rem;*/
}

.demo_style_right_sidebar__1FXNV {
  grid-area: props;
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
  padding-left: 1rem;
  padding-right: 0.25rem;
  background: var(--grey0);
  border-radius: 0.5rem 0 0 0.5rem;
}

.demo_style_footer__UIfuK {
  grid-area: footer;
  background-color: var(--grey0);
  padding: 0.625rem 0.5rem;
  letter-spacing: var(--letter-spacing-body);
  font-size: 0.875rem;
  display: inline-flex;
  position: relative;
  width: 100%;
  bottom: 0;
  height: 3rem;
  z-index: 9;
}

.demo_style_footer_links__X9bAH {
  color: var(--grey70);
  font-weight: 500;
  display: flex;
  font-size: var(--font_size_caption_small);
  letter-spacing: var(--letter_spacing_caption_small);
  margin-left: auto;
}

.demo_style_footer_links__X9bAH a {
  color: var(--grey70);
  text-decoration: none;
  margin-right: 1rem;
  text-align: center;
}

.demo_style_footer_links__X9bAH a:last-of-type {
  margin-right: 0;
}

.demo_style_footer_links__X9bAH a:hover {
  color: var(--blue70);
  text-decoration: none;
}

.demo_style_footer_break__3MMi6 {
  display: block;
  font-size: var(--font_size_caption);
  letter-spacing: var(--letter_spacing_caption);
}

.demo_style_footer_text__3Z3OY {
  text-align: left;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  float: left;
  color: var(--grey50);
  font-size: var(--font_size_caption);
  letter-spacing: var(--letter_spacing_caption);
}

.demo_style_props_button__1kNRS {
  position: fixed;
  bottom: 4rem;
  right: 1rem;
  z-index: 10;
}

.demo_style_nowrap__1izw8 {
  white-space: nowrap;
}

.demo_style_right_sidebar_backdrop__3PAam {
  background-color: unset;
}

div[role="tablist"][class*="tab-list"] {
  margin-left: auto;
  margin-right: auto !important;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

/*-----640PX-----*/
@media screen and (min-width: 640px) {
  .demo_style_container__1w8yB {
    height: calc(100vh);
    display: grid;
    grid-template-areas:
      "header"
      ". "
      "content "
      ". "
      "footer";
    grid-template-columns: 1fr;
    grid-template-rows: 4rem 1rem auto 1rem 3rem;
  }

  .demo_style_right_sidebar__1FXNV {
    /*display: grid;*/
    width: 180px;
    height: calc(100vh - 8rem);
    overflow-y: auto;
    overflow-x: hidden;
    position: fixed;
    top: 4.5rem;
    right: 0;
  }

  .demo_style_content-component__1bkUg {
    display: grid;
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-bottom: 5rem;
  }

  .demo_style_content__17G6x {
    margin-right: 0;
    overflow-y: unset;
    width: calc(100% - 180px);
  }

  .demo_style_footer__UIfuK {
    padding: 0.25rem 0 0.25rem 1rem;
  }

  .demo_style_footer_text__3Z3OY {
    width: auto;
    text-align: left;
    float: left;
    color: var(--grey50);
    font-size: var(--font_size_body);
    line-height: 24px;
    height: 24px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .demo_style_footer_break__3MMi6 {
    display: inline-block;
    font-size: var(--font_size_body);
  }

  .demo_style_footer_links__X9bAH {
    padding-top: initial;
    float: right;
    font-size: var(--font_size_caption);
    letter-spacing: var(--letter_spacing_caption);
  }

  .demo_style_footer_links__X9bAH,
  .demo_style_footer_links__X9bAH a {
    margin-right: 1.5rem;
    margin-bottom: auto;
    margin-top: auto;
  }

  .demo_style_footer_links__X9bAH svg {
    width: 1.25rem;
    height: 1.25rem;
  }

  .demo_style_props_button__1kNRS {
    display: none;
  }
}

/*-----1024PX-----*/
@media screen and (min-width: 1024px) {
  .demo_style_container__1w8yB {
    height: 100vh;
    display: grid;
    grid-template-areas:
      "header header header"
      ". . ."
      "sidebar content props"
      ". . ."
      "footer footer footer";
    grid-template-columns: 240px 5fr 200px;
    grid-template-rows: 4.5rem 1rem auto 1rem 3rem;
  }

  .demo_style_sidebar__2T8Ua {
    grid-area: sidebar;
    display: block;
    max-width: 240px;
    width: 240px;
    top: 4.5rem;
    -webkit-transform: unset;
            transform: unset;
    box-shadow: none;
    height: auto;
    background: none;
  }

  .demo_style_right_sidebar__1FXNV {
    width: 200px;
    height: calc(100vh - 9.5rem);
    top: 5.5rem;
    background: none;
    padding-bottom: 0;
    padding-top: 0;
  }

  .demo_style_content-component__1bkUg {
    display: grid;
    width: 100%;
    float: left;
  }

  .demo_style_content__17G6x {
    margin-right: 0;
    margin-left: 0;
    width: 100%;
    padding: 0 1rem;
  }

  .demo_style_nowrap__1izw8 {
    white-space: initial;
  }
}

@media screen and (min-width: 1440px) {
  .demo_style_container__1w8yB {
    height: calc(100vh);
    display: grid;
    grid-template-areas:
      "header header"
      ". . ."
      "sidebar content"
      ". . ."
      "footer footer";
    grid-template-columns: 200px 1fr;
    grid-template-rows: 4.5rem 1rem auto 0 3rem;
  }

  .demo_style_sidebar__2T8Ua {
    width: 200px;
    max-width: 200px;
  }

  .demo_style_content__17G6x {
    padding: 0;
    padding-left: 0.75rem;
  }

  .demo_style_content-component__1bkUg {
    width: calc(100% - 200px);
    padding: 0;
  }

  .demo_style_right_sidebar__1FXNV {
    position: relative;
    top: 0;
  }

  .demo_style_footer__UIfuK {
    position: absolute;
    left: 0;
  }
}
.container{display:grid;grid-template-areas:"header " "content";grid-template-columns:fit-content(100%);padding-top:1em;padding-left:20px;padding-right:20px}.breadcrumbs{grid-area:header;top:4.5rem}.content{grid-area:content;padding-top:2.5em}.form{display:flex;flex-direction:column;max-width:700px;margin:auto;text-align:center}.button{color:#61dafb}.icon{justify-content:left;background:#00a6e9;text-align:left;max-width:180px}.textField{position:relative;float:left;width:"100%";margin-right:30px}
:local(.labelStyles) label{font-weight:bold;font-size:14px;margin-bottom:10px;display:block;text-align:left}:local(.inputStyles) input{margin-bottom:20px;width:100%;padding:10px;height:10px}
.container{display:grid;grid-template-areas:"header " "content";grid-template-columns:fit-content(100%);padding-top:1em;padding-left:20px;padding-right:20px}.breadcrumbs{grid-area:header;top:4.5rem}.content{grid-area:content;padding-top:2.5em}.form{display:flex;flex-direction:column;max-width:700px;margin:auto;text-align:center}.button{color:#61dafb}.icon{justify-content:left;background:#00a6e9;text-align:left;max-width:180px}.textField{position:relative;float:left;width:"100%";margin-right:30px}
.container{display:grid;grid-template-areas:"header " "content";grid-template-columns:fit-content(100%);padding-top:1em;padding-left:20px;padding-right:20px}.table{min-width:700}.image{width:100%}.root{margin:10;overflow-x:"auto"}.breadcrumbs{grid-area:header;top:4.5rem}.content{grid-area:content;padding-top:2.5em}.form{display:flex;flex-direction:column;max-width:700px;margin:auto;text-align:center}.button{color:#61dafb}.icon{justify-content:left;background:#00a6e9;text-align:left;max-width:180px}.textField{position:relative;float:left;width:"100%";margin-right:30px}.image{width:40;height:40}

.App{text-align:center}.App-logo{height:40vmin;pointer-events:none}.icon{width:20;height:20;color:#fff;font-size:10px}.link{display:"flex";color:#fff;font-size:18px}.linkicon{color:#fff;font-size:15px}@media(prefers-reduced-motion: no-preference){.App-logo{-webkit-animation:App-logo-spin infinite 20s linear;animation:App-logo-spin infinite 20s linear}}.main-wrapper{position:relative;min-height:100vh}.App-header{background-color:#282c34;min-height:100vh;display:flex;flex-direction:column;align-items:center;justify-content:center;font-size:calc(10px + 2vmin);color:#fff}.App-link{color:#61dafb}
.style_container__3iy6J {
  padding-right: 1rem;
  padding-left: 1rem;
  font-family: var(--Acumin)
}

.style_sub_title__16nOJ {
  padding-top: 1rem;
}

.style_h4_title__DOzTi {

  padding-right: 5rem;
  font-family: var(--Acumin);
}

.style_h5_title__h1M-x {

  padding-right: 5rem;
  font-family: var(--Acumin);

}

.style_text__1nhCT {
  padding-top: 0.5rem;
  padding-right: 5rem;
  font-family: var(--Acumin);
}

.style_link__2mq18,
.style_link__2mq18:active {
  color: var(--blue60);
  margin: 0 0.25rem;
  text-decoration: underline;
}

.style_link__2mq18:hover {
  color: var(--blue70);
}

.style_promo_group__1WkxE {
  display: flex;
  flex-direction: column;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.style_promo_item__1fj_p {
  width: 100%;
  padding-bottom: 1.5rem;
}

.style_item_img_container__2j5mg {
  border-radius: 0.5rem;
  padding: 0.25rem;

}

.style_item_logo__2poU5 {
  padding-right: 5rem;
  width: 25%;

}

.style_item_img__QOkoS {
  padding-right: 5rem;
  width: 50%;
  height: 50%
}

@media screen and (min-width: 640px) {
  .style_promo_group__1WkxE {
    display: flex;
    flex-direction: initial;
    justify-content: space-between;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .style_promo_item__1fj_p {
    width: calc(25% - 0.75rem);
    padding-bottom: 1.5rem;
  }
}
.style_container__6xT9L {
  padding-right: 1rem;
  padding-left: 1rem;
  font-family: var(--Acumin)
}

.style_sub_title__1RHS9 {
  padding-top: 1rem;
}

.style_h4_title__DaFrX {

  padding-right: 5rem;
  font-family: var(--Acumin);
}

.style_h5_title__331in {

  padding-right: 5rem;
  font-family: var(--Acumin);

}

.style_text__r0a6q {
  padding-top: 0.5rem;
  padding-right: 5rem;
  font-family: var(--Acumin);
}

.style_notebook__D-zgY {
  padding-top: 0.5rem;
  padding-right: 5rem;

}

.style_link__-mXME,
.style_link__-mXME:active {
  color: var(--blue60);
  margin: 0 0.25rem;
  text-decoration: underline;
}

.style_link__-mXME:hover {
  color: var(--blue70);
}

.style_promo_group__21XTl {
  display: flex;
  flex-direction: column;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.style_promo_item__1QPq2 {
  width: 100%;
  padding-bottom: 1.5rem;
}

.style_item_img_container__2NALQ {
  border-radius: 0.5rem;
  padding: 0.25rem;

}

.style_item_logo__O8rIk {
  padding-right: 5rem;
  width: 29%;

}

.style_item_img__1kZHW {
  padding-right: 5rem;
  width: 100%;

}

@media screen and (min-width: 640px) {
  .style_promo_group__21XTl {
    display: flex;
    flex-direction: initial;
    justify-content: space-between;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .style_promo_item__1QPq2 {
    width: calc(25% - 0.75rem);
    padding-bottom: 1.5rem;
  }
}
.App{text-align:center}.App-logo{height:40vmin;pointer-events:none}#container{display:"flex";align-items:"baseline";text-align:center}@media(prefers-reduced-motion: no-preference){.App-logo{-webkit-animation:App-logo-spin infinite 20s linear;animation:App-logo-spin infinite 20s linear}}.main-wrapper{position:relative;min-height:100vh}.App-header{background-color:#282c34;min-height:100vh;display:flex;flex-direction:column;align-items:center;justify-content:center;font-size:calc(10px + 2vmin);color:#fff}.App-link{color:#61dafb}@-webkit-keyframes App-logo-spin{from{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}@keyframes App-logo-spin{from{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}
.container{display:grid;grid-template-areas:"header " "content";grid-template-columns:fit-content(100%);padding-top:1em;padding-left:20px;padding-right:20px}.breadcrumbs{grid-area:header;top:4.5rem}.content{grid-area:content;padding-top:2.5em}.form{display:flex;flex-direction:column;max-width:700px;margin:auto;text-align:center}.button{color:#61dafb}.icon{justify-content:left;background:#00a6e9;text-align:left;max-width:180px}.textField{position:relative;float:left;width:"100%";margin-right:30px}
:local(.styles) button{color:#fff}
.style_layout__2jdKG {
  display: flex;
  background: transparent;
  border: 1px solid var(--grey20);
  border-radius: 0.5rem;
  padding: 1rem;
  margin: 1rem;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.style_grid_item__16Tot {
  display: flex;
  flex-direction: column;
  /* padding: 40px 0; */
  align-items: center;
  border-radius: 1rem;
  background: transparent;
}
.style_grid_item__16Tot:hover {
  cursor: pointer;
  background: var(--color10);
  transition: all 0.25s ease-in-out;
}

.style_item__icon__fzX5m {
  width: 6.5em;
  height: 6.5em;
  background: var(--color50);
  border-radius: 1rem;
  color: white;
  justify-content: center;
  display: flex;
  align-items: center;
}
.style_item__title__1NCjl {
  text-align: center;
  margin-top: 1.5rem;
  white-space: pre-wrap;
}

@media (min-width: 540px) {
  .style_layout__2jdKG {
    justify-content: center;
  }
}

/* TYPOGRAPHY TYPES*/

._type_roboto__3HKIv {
  font-family: var(--roboto);
}
._type_inter__1wmBQ {
  font-family: var(--inter);
}
._type_lato__2FajI {
  font-family: var(--lato);
}
._type_plex__1F8WA {
  font-family: var(--plex);
}
._type_manrope__1hC_x {
  font-family: var(--manrope);
}

._type_h1__3C36R {
  font-weight: var(--font_weight_h1);
  font-size: var(--font_size_h1);
  letter-spacing: var(--letter_spacing_h1);
  line-height: 120%;
}

._type_h2__1Iviv {
  font-weight: var(--font_weight_h2);
  font-size: var(--font_size_h2);
  letter-spacing: var(--letter_spacing_h2);
  line-height: 120%;
}

._type_h3__1het9 {
  font-weight: var(--font_weight_h3);
  font-size: var(--font_size_h3);
  letter-spacing: var(--letter_spacing_h3);
  line-height: 124%;
}

._type_h4__2taKn {
  font-weight: var(--font_weight_h4);
  font-size: var(--font_size_h4);
  letter-spacing: var(--letter_spacing_h4);
  line-height: 128%;
}

._type_h5__1xP_E {
  font-weight: var(--font_weight_h5);
  font-size: var(--font_size_h5);
  letter-spacing: var(--letter_spacing_h5);
  line-height: 150%;
}

._type_h6__34Mxu {
  font-weight: var(--font_weight_h6);
  font-size: var(--font_size_h6);
  letter-spacing: var(--letter_spacing_h6);
  line-height: 150%;
}

._type_body__1yVDB {
  font-weight: var(--font_weight_body);
  font-size: var(--font_size_body);
  letter-spacing: var(--letter_spacing_body);
  line-height: 150%;
}
._type_body_bold__5ozgR {
  font-weight: var(--font_weight_body_medium);
  letter-spacing: var(--letter_spacing_body_bold);
}
._type_body__1yVDB._type_large__1XBEK {
  font-size: var(--font_size_body_large);
  letter-spacing: var(--letter_spacing_body_large);
  line-height: 158%;
}
._type_body_bold__5ozgR._type_large__1XBEK {
  font-size: var(--font_size_body_large);
  letter-spacing: var(--letter_spacing_body_large_bold);
  line-height: 158%;
}
._type_body__1yVDB._type_small__2espG,
._type_body_bold__5ozgR._type_small__2espG {
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
  line-height: 143%;
}
._type_button_text__1xJ4K {
  font-weight: var(--font_weight_button);
  font-size: var(--font_size_button);
  letter-spacing: var(--letter_spacing_button);
  line-height: 114%;
}

._type_caption__vVn_F {
  font-weight: var(--font_weight_caption);
  font-size: var(--font_size_caption);
  letter-spacing: var(--letter_spacing_caption);
  line-height: 126%;
}
._type_caption__vVn_F._type_small__2espG {
  font-size: var(--font_size_caption_small);
  font-weight: var(--font_weight_caption_small);
  letter-spacing: var(--letter_spacing_caption_small);
  line-height: 120%;
}

._type_overline__13yat {
  font-weight: var(--font_weight_overline);
  font-size: var(--font_size_overline);
  letter-spacing: var(--letter_spacing_overline);
  text-transform: uppercase;
  line-height: 126%;
}
._type_overline__13yat._type_small__2espG {
  font-size: var(--font_size_overline_small);
  letter-spacing: var(--letter_spacing_overline_small);
  line-height: 120%;
}

._view_color0__EGchp {
  color: var(--color0);
}
._view_color5__2OgOH {
  color: var(--color5);
}
._view_color10__3sQZY {
  color: var(--color10);
}
._view_color20__bqNqc {
  color: var(--color20);
}
._view_color30__M58EF {
  color: var(--color30);
}
._view_color40__1GkMC {
  color: var(--color40);
}
._view_color50__1hp8T {
  color: var(--color50);
}
._view_color60__2wwvl {
  color: var(--color60);
}
._view_color70__2YTNK {
  color: var(--color70);
}
._view_color80__26oUB {
  color: var(--color80);
}
._view_color90__3BRw0 {
  color: var(--color90);
}
._view_color100__1ZRo0 {
  color: var(--color100);
}

/**TOAST*/
._type_def__Ot4ak > div {
  height: auto;
  border-radius: 0.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  z-index: 999;
}
._type_def__Ot4ak span[class*="toast-message"] {
  padding: 0;
  margin: auto;
  margin-left: 1rem;
  font-size: var(--font_size_h6);
  font-family: var(--font_secondary);
  letter-spacing: var(--letter_spacing_h6);
  font-weight: bold;
}
._type_def__Ot4ak div[class*="button-group"] {
  padding: 0;
  margin: auto;
  margin-right: 1rem;
}
._type_def__Ot4ak div[class*="button-group"] svg {
  width: 1.5rem;
  height: 1.5rem;
}
._type_def__Ot4ak div[class*="button-group"] a {
  margin-left: 0.5rem;
  font-family: var(--font_primary);
  text-transform: uppercase;
  font-size: var(--font_size_button);
  letter-spacing: var(--letter_spacing_button);
  font-weight: var(--font_weight_button);
}

._type_def__Ot4ak span[class*="icon"]:first-child {
  float: left;
  margin: auto;
  border-radius: 99px;
  margin-left: 1rem;
  width: 2.5rem;
  height: 2.5rem;
}
._type_def__Ot4ak span[class*="icon"]:first-child > svg {
  margin: auto;
  margin-top: 0.5rem;
}

._type_def__Ot4ak._type_no-close__1e_xr div[class*="button-group"] button[class*="button"] {
  display: none;
}

/*TOAST*/

/*flat*/

/*filled*/

._view_filled__18yDS > div {
  background-color: var(--color70);
  color: var(--color10);
  box-shadow: none;
}
._view_filled__18yDS div[class*="button-group"] svg {
  color: var(--color40);
  transition: all 180ms ease-in;
}
._view_filled__18yDS div[class*="button-group"] > button:hover svg,
._view_filled__18yDS div[class*="button-group"] > button[class*="button"]:hover,
._view_filled__18yDS div[class*="button-group"] a[class*="button"]:hover {
  color: var(--color10);
  background: none;
  transition: all 180ms ease-in;
}
._view_filled__18yDS div[class*="button-group"] a {
  color: var(--color30);
  transition: all 180ms ease-in;
}

._view_filled__18yDS svg,
._view_filled__18yDS span[class*="icon"]:first-child {
  color: var(--color10);
}

._view_filled__18yDS div > span[class*="icon"]:first-child {
  background: var(--color80);
}

/*smooth*/

._view_smooth__ErOP5 > div {
  background-color: var(--color5);
  color: var(--color80);
  box-shadow: none;
}
._view_smooth__ErOP5 div[class*="button-group"] svg {
  color: var(--color40);
  transition: all 180ms ease-in;
}
._view_smooth__ErOP5 div[class*="button-group"] > button:hover svg,
._view_smooth__ErOP5 div[class*="button-group"] > button[class*="button"]:hover {
  color: var(--color80);
  background: none;
  transition: all 180ms ease-in;
}
._view_smooth__ErOP5 div[class*="button-group"] a[class*="button"] {
  color: var(--color70);
  background: var(--white);
  padding: 0.75rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
  margin-left: 0.5rem;
  border-radius: 4px;
  box-shadow: var(--shadow4dp);
  transition: all 180ms ease-in;
}
._view_smooth__ErOP5 div[class*="button-group"] a[class*="button"]:hover {
  color: var(--color70);
  background: var(--white);
  box-shadow: var(--shadow8dp);
}
._view_smooth__ErOP5 div[class*="button-group"] a[class*="button"]:active {
  box-shadow: var(--shadow2dp);
}

._view_smooth__ErOP5 svg,
._view_smooth__ErOP5 span[class*="icon"]:first-child {
  color: var(--color80);
}

._view_smooth__ErOP5 div > span[class*="icon"]:first-child {
  background: var(--color20);
}

/*OUTLINED*/

._view_outlined__26my8 > div {
  background-color: var(--white);
  color: var(--color80);
  border: 1px solid var(--color30);
  box-shadow: none;
}
._view_outlined__26my8 div[class*="button-group"] svg {
  color: var(--color40);
  transition: all 180ms ease-in;
}
._view_outlined__26my8 div[class*="button-group"] > button:hover svg,
._view_outlined__26my8 div[class*="button-group"] > button[class*="button"]:hover {
  color: var(--color80);
  background: none;
  transition: all 180ms ease-in;
}
/*close button*/
._view_outlined__26my8 div[class*="button-group"] a[class*="button"] {
  color: var(--color70);
  background: var(--color5);
  padding: 0.75rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
  margin-left: 0.5rem;
  border-radius: 4px;
  /*box-shadow: var(--shadow4dp);*/
  transition: all 180ms ease-in;
}
._view_outlined__26my8 div[class*="button-group"] a[class*="button"]:hover {
  color: var(--color70);
  background: var(--color5);
  box-shadow: var(--shadow4dp);
}
._view_outlined__26my8 div[class*="button-group"] a[class*="button"]:active {
  box-shadow: none;
  background: var(--color10);
}
/**/

/*icon*/
._view_outlined__26my8 svg,
._view_outlined__26my8 span[class*="icon"]:first-child {
  color: var(--color80);
}

._view_outlined__26my8 div > span[class*="icon"]:first-child {
  background: var(--color10);
}

/*RAISED*/

._view_raised__BzzHz > div {
  background-color: var(--white);
  color: var(--color80);
  box-shadow: var(--shadow4dp);
}
._view_raised__BzzHz div[class*="button-group"] svg {
  color: var(--grey50);
  transition: all 180ms ease-in;
}
._view_raised__BzzHz div[class*="button-group"] > button:hover svg,
._view_raised__BzzHz div[class*="button-group"] > button[class*="button"]:hover {
  color: var(--grey90);
  background: none;
  transition: all 180ms ease-in;
}
/*close button*/
._view_raised__BzzHz div[class*="button-group"] a[class*="button"] {
  color: var(--color70);
  background: var(--color5);
  padding: 0.75rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
  margin-left: 0.5rem;
  border-radius: 4px;
  /*box-shadow: var(--shadow4dp);*/
  transition: all 180ms ease-in;
}
._view_raised__BzzHz div[class*="button-group"] a[class*="button"]:hover {
  color: var(--color70);
  background: var(--color5);
  box-shadow: var(--shadow4dp);
}
._view_raised__BzzHz div[class*="button-group"] a[class*="button"]:active {
  box-shadow: none;
  background: var(--color10);
}
/*icon*/
._view_raised__BzzHz svg,
._view_raised__BzzHz span[class*="icon"]:first-child {
  color: var(--color80);
}
._view_raised__BzzHz div > span[class*="icon"]:first-child {
  background: var(--color10);
}

.style_layout__3g6J5 {
  display: flex;
  background: transparent;
  border: 1px solid var(--grey20);
  border-radius: 0.5rem;
  padding: 1rem;
  margin: 1rem;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.style_grid_item__2xWCj {
  display: flex;
  flex-direction: column;
  /* padding: 40px 0; */
  align-items: center;
  border-radius: 1rem;
  background: transparent;
}
.style_grid_item__2xWCj:hover {
  cursor: pointer;
  background: var(--color10);
  transition: all 0.25s ease-in-out;
}

.style_item__icon__TSWYa {
  width: 6.5em;
  height: 6.5em;
  background: var(--color50);
  border-radius: 1rem;
  color: white;
  justify-content: center;
  display: flex;
  align-items: center;
}
.style_item__title__2HGrF {
  text-align: center;
  margin-top: 1.5rem;
  white-space: pre-wrap;
}

@media (min-width: 540px) {
  .style_layout__3g6J5 {
    justify-content: center;
  }
}

._type_dropdown__1xgpn,
._type_dropdown--dense__3QQhd,
._type_dropdown__1xgpn:after,
._type_dropdown--dense__3QQhd::after {
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

._type_dropdown__1xgpn {
  height: 2.5rem;
  padding: 0.5rem 0.75rem;
  border-radius: 0.25rem;
}
._type_dropdown--dense__3QQhd {
  height: 1.75rem;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-size: var(--font_size_body_small);
}

._type_dropdown--dense__3QQhd::after,
._type_dropdown__1xgpn::after {
  display: inline-block;
  margin-left: 1rem;
  vertical-align: 0.1em;
  content: "";
  border-top: 0.4em solid;
  border-right: 0.35em solid transparent;
  border-bottom: 0;
  border-left: 0.35em solid transparent;
  border-radius: 0.125rem;
}

._type_dropdown__1xgpn:hover,
._type_dropdown--dense__3QQhd:hover {
  cursor: pointer;
}

._view_popover__3skGE[class*="minimal"] {
  box-shadow: none;
  margin-top: 4px !important;
  background: transparent;
}
._view_popover__3skGE > div[class*="popover-content"] {
  background: transparent;
}
/*CARET-DOWN*/
._view_outlined__dPrMl::after,
._view_raised__NtdJO::after,
._view_smooth__3GH5a::after {
  color: var(--color40);
}
._view_outlined__dPrMl:hover::after,
._view_raised__NtdJO:hover::after,
._view_smooth__3GH5a:hover::after {
  color: var(--color60);
}
._view_outlined__dPrMl:active::after,
._view_outlined__dPrMl:focus::after,
._view_raised__NtdJO:active::after,
._view_raised__NtdJO:focus::after,
._view_smooth__3GH5a:active::after,
._view_smooth__3GH5a:focus::after {
  color: var(--color90);
}
._view_filled__1zPhA::after {
  color: var(--color10);
}
._view_filled__1zPhA:hover::after {
  color: var(--color5);
}
._view_filled__1zPhA:active::after,
._view_filled__1zPhA:focus::after {
  color: var(--color0);
}
/**/

/*OUTLINED DROPDOWN*/
._view_outlined__dPrMl {
  margin: 2px;
  border: none;
  color: var(--color100);
  background: var(--white);
  box-shadow: 0 0 0 1px var(--color30);
}
._view_outlined__dPrMl:hover {
  box-shadow: 0 0 0 1px var(--color80);
}
._view_outlined__dPrMl:active,
._view_outlined__dPrMl:focus {
  box-shadow: 0 0 0 2px var(--color80);
}

/* RAISED DROPDOWN*/
._view_raised__NtdJO {
  border: none;
  color: var(--color100);
  background: var(--white);
  box-shadow: var(--shadow2dp);
}
._view_raised__NtdJO:hover {
  box-shadow: var(--shadow4dp);
}
._view_raised__NtdJO:active,
._view_raised__NtdJO:focus {
  box-shadow: var(--shadow8dp);
}

/* SMOOTH DROPDOWN*/
._view_smooth__3GH5a {
  border: none;
  color: var(--color100);
  background: var(--color5);
}
._view_smooth__3GH5a:hover {
  background: var(--color10);
}
._view_smooth__3GH5a:active,
._view_smooth__3GH5a:focus {
  background: var(--color20);
}

/* FILLED DROPDOWN*/
._view_filled__1zPhA {
  border: none;
  color: var(--color0);
  background: var(--color40);
}
._view_filled__1zPhA:hover {
  background: var(--color50);
}
._view_filled__1zPhA:active,
._view_filled__1zPhA:focus {
  background: var(--color70);
  box-shadow: var(--shadow8dp);
}

/**CARD*/

._type_def__2JvC4 {
  border-radius: 0.5rem;
  padding: 0;
}

/*CARD*/
/*filled*/
._view_filled__1rZRc {
  background-color: var(--color50);
  border: none;
  box-shadow: var(--shadow2dp);
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_filled__1rZRc[class*="interactive"]:hover {
  box-shadow: var(--shadow8dp);
  background-color: var(--color70);
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_filled__1rZRc[class*="interactive"]:active {
  box-shadow: var(--shadow2dp);
  background-color: var(--color50);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}

/*smooth*/
._view_smooth__30YIC {
  background-color: var(--color0);
  border: none;
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_smooth__30YIC[class*="interactive"]:hover {
  background-color: var(--color10);
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_smooth__30YIC[class*="interactive"]:active {
  box-shadow: none;
  background-color: var(--color0);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}

/*outlined*/
._view_outlined__25LbV {
  background-color: var(--white);
  border: 1px solid var(--color20);
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_outlined__25LbV[class*="interactive"]:hover {
  border: 1px solid var(--color30);
  box-shadow: 0 0 0 1px var(--color30);
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_outlined__25LbV[class*="interactive"]:active {
  box-shadow: none;
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}
/*raised*/
._view_raised__lh08l {
  background-color: var(--white);
  box-shadow: var(--shadow2dp);
  border: none;
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_raised__lh08l[class*="interactive"]:hover {
  box-shadow: var(--shadow8dp);
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_raised__lh08l[class*="interactive"]:active {
  box-shadow: var(--shadow2dp);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}

/*MENU SHAPE CSS MODULE*/
/*DEFAULT*/
._type_def__2AI0b,
._type_def__2AI0b div[class*="popover-content"] > ul[class*="menu"] {
  border-radius: 0.5rem;
  margin: 0;
  padding: 0;
  font-size: var(--font_size_body);
  letter-spacing: var(--letter_spacing_body);
}
._type_def__2AI0b div[class*="popover-content"] {
  border-radius: 0.5rem;
}
._type_def__2AI0b > li:not([class*="menu-divider"]),
._type_def__2AI0b
  div[class*="popover-content"]
  > ul[class*="menu"]
  > li:not([class*="menu-divider"]) {
  height: 2.5rem;
  margin: 0;
}
._type_def__2AI0b > li:first-of-type,
._type_def__2AI0b div[class*="popover-content"] > ul[class*="menu"] > li:first-of-type {
  padding-top: 0.5rem;
  line-height: 3rem;
  height: 3rem;
}
._type_def__2AI0b > li:last-of-type,
._type_def__2AI0b div[class*="popover-content"] > ul[class*="menu"] > li:last-of-type {
  padding-bottom: 0.5rem;
  height: 3rem;
}
._type_def__2AI0b div[class*="popover"][class*="submenu"],
._type_dense__25rz2 div[class*="popover"][class*="submenu"] {
  padding: 0;
  margin-left: -0.0625rem;
}
._type_def__2AI0b div[class*="popover"][class*="submenu"],
._type_dense__25rz2 div[class*="popover"][class*="submenu"] {
  -webkit-animation: _type_slide-fwd-right__RacXV 0.28s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
          animation: _type_slide-fwd-right__RacXV 0.28s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/*Heading divider*/
._type_def__2AI0b > li[class*="menu-header"]:not([class*="menu-divider"]),
._type_dense__25rz2 > li[class*="menu-header"]:not([class*="menu-divider"]) {
  margin-top: 0.5rem;
}
/*----*/

/*DENSE*/
._type_dense__25rz2,
._type_dense__25rz2 div[class*="popover-content"] > ul[class*="menu"] {
  border-radius: 0.375rem;
  margin: 0;
  padding: 0;
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
}

._type_dense__25rz2 div[class*="popover-content"] {
  border-radius: 0.375rem;
}
._type_dense__25rz2 > li:not([class*="menu-divider"]),
._type_dense__25rz2
  div[class*="popover-content"]
  > ul[class*="menu"]
  > li:not([class*="menu-divider"]) {
  height: 1.75rem;
  margin: 0;
}
._type_dense__25rz2 > li:first-of-type,
._type_dense__25rz2 div[class*="popover-content"] > ul[class*="menu"] > li:first-of-type {
  padding-top: 0.5rem;
  line-height: 2.25rem;
  height: 2.25rem;
}
._type_dense__25rz2 > li:last-of-type,
._type_dense__25rz2 div[class*="popover-content"] > ul[class*="menu"] > li:last-of-type {
  padding-bottom: 0.5rem;
  height: 2.25rem;
}

/*-------------------*/

@-webkit-keyframes _type_slide-fwd-right__RacXV {
  0% {
    -webkit-transform: scale(0.9) translateX(-20%);
            transform: scale(0.9) translateX(-20%);
  }
  100% {
    -webkit-transform: scale(1) translateX(0);
            transform: scale(1) translateX(0);
  }
}

@keyframes _type_slide-fwd-right__RacXV {
  0% {
    -webkit-transform: scale(0.9) translateX(-20%);
            transform: scale(0.9) translateX(-20%);
  }
  100% {
    -webkit-transform: scale(1) translateX(0);
            transform: scale(1) translateX(0);
  }
}

/*MENU VIEW*/
/*SMOOTH*/
._view_smooth__1gn7B,
._view_smooth__1gn7B div[class*="popover-content"] > ul[class*="menu"] {
  box-shadow: var(--shadow2dp);
  color: var(--color100);
  background: var(--color0);
}
._view_smooth__1gn7B div[class*="popover"] > div[class*="popover-content"] {
  box-shadow: none;
}
._view_smooth__1gn7B svg {
  color: var(--color70);
}
._view_smooth__1gn7B > li:not([class*="menu-divider"]) a {
  background-color: unset;
}
._view_smooth__1gn7B > li:not([class*="menu-divider"]) a:hover:not([class*="disabled"]) {
  background-color: var(--white);
}
._view_smooth__1gn7B a[class*="disabled"] > div,
._view_smooth__1gn7B a > span[class*="label"],
._view_smooth__1gn7B a[class*="disabled"] > span[class*="item-label"],
._view_smooth__1gn7B a[class*="disabled"] svg,
._view_smooth__1gn7B a > span[class*="label"] > span > svg {
  color: var(--color40) !important;
}
._view_smooth__1gn7B
  > li[class*="submenu"]
  span[class*="popover-wrapper"]
  > span[class*="popover-open"]
  > a:not(:hover) {
  background-color: var(--color5);
}

/*OUTLINED*/
._view_outlined__1Br2X,
._view_outlined__1Br2X div[class*="popover-content"] > ul[class*="menu"] {
  box-shadow: none;
  color: var(--color100);
  background: var(--white);
  border: 0.125rem solid var(--color30);
}
._view_outlined__1Br2X div[class*="popover"] > div[class*="popover-content"] {
  box-shadow: none;
}
._view_outlined__1Br2X svg {
  color: var(--color70);
}
._view_outlined__1Br2X > li:not([class*="menu-divider"]) a {
  background-color: unset;
}
._view_outlined__1Br2X > li:not([class*="menu-divider"]) a:hover:not([class*="disabled"]) {
  background-color: var(--color5);
}
._view_outlined__1Br2X a[class*="disabled"] > div,
._view_outlined__1Br2X a > span[class*="label"],
._view_outlined__1Br2X a[class*="disabled"] > span[class*="item-label"],
._view_outlined__1Br2X a[class*="disabled"] svg,
._view_outlined__1Br2X a > span[class*="label"] > span > svg {
  color: var(--color40) !important;
}
._view_outlined__1Br2X
  > li[class*="submenu"]
  span[class*="popover-wrapper"]
  > span[class*="popover-open"]
  > a:not(:hover) {
  background-color: var(--color5);
}
/*-----*/
/*RAISED*/
._view_raised__3C3yV,
._view_raised__3C3yV div[class*="popover-content"] > ul[class*="menu"] {
  box-shadow: var(--shadow8dp);
  color: var(--color100);
  background: var(--white);
}
._view_raised__3C3yV div[class*="popover"] > div[class*="popover-content"] {
  box-shadow: none;
}
._view_raised__3C3yV svg {
  color: var(--color70);
}
._view_raised__3C3yV > li:not([class*="menu-divider"]) a {
  background-color: unset;
}
._view_raised__3C3yV > li[class*="menu-divider"] {
  border-top-color: var(--color10);
}
._view_raised__3C3yV > li:not([class*="menu-divider"]) a:hover:not([class*="disabled"]) {
  background-color: var(--color5);
}
._view_raised__3C3yV a[class*="disabled"] > div,
._view_raised__3C3yV a > span[class*="label"],
._view_raised__3C3yV a[class*="disabled"] > span[class*="item-label"],
._view_raised__3C3yV a[class*="disabled"] svg,
._view_raised__3C3yV a > span[class*="label"] > span > svg {
  color: var(--color40) !important;
}
._view_raised__3C3yV
  > li[class*="submenu"]
  span[class*="popover-wrapper"]
  > span[class*="popover-open"]
  > a:not(:hover) {
  background-color: var(--color5);
}
/*-----*/
/*FILLED*/
._view_filled__1QvMF,
._view_filled__1QvMF div[class*="popover-content"] > ul[class*="menu"] {
  box-shadow: var(--shadow8dp);
  color: var(--color0);
  background: var(--color70);
}
._view_filled__1QvMF div[class*="popover"] > div[class*="popover-content"] {
  box-shadow: none;
}
._view_filled__1QvMF svg {
  color: var(--color40);
}
._view_filled__1QvMF > li:not([class*="menu-divider"]) a {
  background-color: transparent;
}
._view_filled__1QvMF > li[class*="menu-divider"] {
  border-top-color: var(--color80);
}
._view_filled__1QvMF > li:not([class*="menu-divider"]) a:hover:not([class*="disabled"]) {
  background-color: var(--color90);
}
._view_filled__1QvMF a[class*="disabled"] > div,
._view_filled__1QvMF a > span[class*="label"],
._view_filled__1QvMF a[class*="disabled"] > span[class*="item-label"],
._view_filled__1QvMF a[class*="disabled"] svg,
._view_filled__1QvMF a > span[class*="label"] > span > svg {
  color: var(--color40) !important;
}
._view_filled__1QvMF
  > li[class*="submenu"]
  span[class*="popover-wrapper"]
  > span[class*="popover-open"]
  > a:not(:hover) {
  background-color: var(--color90);
}

/*MENU ITEM CSS MODULE*/
/*DEFAULT*/
._type_def__DbPIr,
._type_dense__13yj_ {
  line-height: 2.5rem;
  padding: 0;
  margin: 0 0.5rem;
  border-radius: 0.25rem;
}
._type_def__DbPIr > div[class*="fill"] {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}
._type_def__DbPIr > span[class*="icon"],
._type_dense__13yj_ > span[class*="icon"] {
  margin-bottom: auto;
  margin-top: auto;
  margin-left: 0.75rem;
  margin-right: -0.25rem;
}

._type_def__DbPIr > span[class*="menu-item-label"],
._type_dense__13yj_ > span[class*="menu-item-label"] {
  margin-bottom: auto;
  margin-top: auto;
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}
._type_def__DbPIr > span[class*="icon"] > svg {
  width: 1.25rem;
  height: 1.25rem;
}
._type_def__DbPIr > span[class*="menu-item-label"] > span[class*="icon"],
._type_dense__13yj_ > span[class*="menu-item-label"] > span[class*="icon"] {
  display: block;
}

/*DENSE*/
._type_dense__13yj_ {
  line-height: 1.75rem;
}
._type_dense__13yj_ > div[class*="fill"] {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}
._type_dense__13yj_ > span[class*="icon"] {
  margin-left: 0.5rem;
  margin-right: -0.125rem;
}
._type_dense__13yj_ > span[class*="menu-item-label"] {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}
/*--------------*/
._type_def__DbPIr > span[class*="icon-caret-right"],
._type_dense__13yj_ > span[class*="icon-caret-right"] {
  margin-right: 0.5rem;
}

/*MENU ITEM*/
._view_smooth__QYHcY._view_note__215XJ,
._view_outlined__2d69l._view_note__215XJ,
._view_raised__3KmYd._view_note__215XJ {
  color: var(--color80);
}
._view_filled__30XGJ._view_note__215XJ {
  color: var(--color10);
}

/*MENU DIVIDER SHAPE CSS MODULE*/

._type_def__T8gSw,
._type_dense__35Spz {
  margin: 0.5rem;
  padding: 0;
}
._type_def__T8gSw > h6 {
  line-height: 2.5rem;
  padding: 0;
  margin-left: 0.5rem;
}

._type_dense__35Spz > h6 {
  line-height: 1.75rem;
  padding: 0;
  margin-left: 0.5rem;
}

/*MENU DIVIDER VIEW*/

._view_smooth__3HlmG > h6,
._view_outlined__1jYUi > h6 {
  color: var(--color80);
}
._view_filled__3wftX > h6 {
  color: var(--color30);
}

._view_smooth__3HlmG,
._view_outlined__1jYUi {
  border-top: 0.0625rem solid var(--color10);
}

.style_container__1sNh_ {
  margin: 2.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.style_cards_row__Rzjr-,
.style_cards_row_last__n4ZcW {
  display: flex;
  flex: 1 1 auto;
  margin: 1rem;
}
.style_cards_row_last__n4ZcW {
  justify-content: space-between;
}
.style_cards_row_last__n4ZcW > div:not(:last-child) {
  margin-right: 1rem;
}

.style_item__icon__1lFSU {
  color: var(--color40);
  justify-content: flex-start;
  display: flex;
  margin-bottom: 1rem;
}
.style_card_row_add__3ECn8 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* max-width: 290px; */
  margin: 1rem 3rem;
}
.style_card_row_add__3ECn8 > div {
  margin-bottom: 0.5rem;
}
.style_dialog__qhxG- div[class*="footer"] {
  flex-direction: column;
}
.style_dialog__qhxG- div[class*="footer"] button {
  width: 100%;
}
.style_dialog__qhxG- div[class*="right-button"] {
  margin-top: 1.5rem;
}

@media (min-width: 640px) {
  .style_cards_row__Rzjr- > div:not(:last-child) {
    margin-right: 1rem;
  }
}

.style_container__1J60m {
  margin: 2.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.style_cards_row__2S5p7,
.style_cards_row_last__2ey6P {
  display: flex;
  flex: 1 1 auto;
  margin: 1rem;
}
.style_cards_row_last__2ey6P {
  justify-content: space-between;
}
.style_cards_row_last__2ey6P > div:not(:last-child) {
  margin-right: 1rem;
}

.style_item__icon__3Re_v {
  color: var(--color40);
  justify-content: flex-start;
  display: flex;
  margin-bottom: 1rem;
}
.style_card_row_add__2uoh4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* max-width: 290px; */
  margin: 1rem 3rem;
}
.style_card_row_add__2uoh4 > div {
  margin-bottom: 0.5rem;
}
.style_dialog__1mh_q div[class*="footer"] {
  flex-direction: column;
}
.style_dialog__1mh_q div[class*="footer"] button {
  width: 100%;
}
.style_dialog__1mh_q div[class*="right-button"] {
  margin-top: 1.5rem;
}

@media (min-width: 640px) {
  .style_cards_row__2S5p7 > div:not(:last-child) {
    margin-right: 1rem;
  }
}

/* DIALOG TYPES*/
/*--DEFAULT--*/
._type_default__36UOQ {
  width: 98%;
  max-width: 30.5rem;
  padding: 2.5rem;
  border-radius: 1.5rem;
}

._type_header__2AjJL {
  display: flex;
  margin-bottom: 1rem;
}
._type_header__2AjJL > span[class*="icon"] {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 1rem;
}
._type_footer__QVdEM {
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
}
._type_left-button__T0zaj {
  /* float: left; */
}
._type_right-button__1e4a0 {
  /* float: right; */
}

._type_dense__3Ofzo {
  width: 98%;
  max-width: 20.5rem;
  padding: 1rem;
  border-radius: 1rem;
}
._type_dense__3Ofzo._type_header__2AjJL {
  margin-bottom: 0.75rem;
}
._type_dense__3Ofzo._type_header__2AjJL > span[class*="icon"] {
  margin-right: 0.75rem;
}
._type_dense__3Ofzo._type_footer__QVdEM {
  margin-top: 1.75rem;
}

._type_close-button__3X8bj {
  margin-right: 0;
  margin-left: auto;
}

/*DIALOG STYLE*/

/*SMOOTH*/
._view_smooth__2irKU {
  background: var(--color5);
  box-shadow: none;
}
/*RAISED*/
._view_raised__2Sv4j {
  background: var(--white);
  box-shadow: var(--shadow24dp);
}
/*FILLED*/
._view_filled__Pnyfv {
  background: var(--color50);
  box-shadow: none;
}
._view_filled__Pnyfv > div[class*="header"] > div,
._view_filled__Pnyfv > div[class*="body"] {
  color: var(--color0);
}
/*ICON*/
._view_filled__Pnyfv > div[class*="header"] > span[class*="icon"] > svg,
._view_raised__2Sv4j > div[class*="header"] > span[class*="icon"] > svg,
._view_smooth__2irKU > div[class*="header"] > span[class*="icon"] > svg {
  fill: var(--color30);
}
/*CLOSE_BUTTON*/
._view_filled__Pnyfv > div[class*="header"] div[class*="close-button"] svg,
._view_raised__2Sv4j > div[class*="header"] div[class*="close-button"] svg,
._view_smooth__2irKU > div[class*="header"] div[class*="close-button"] svg {
  fill: var(--color30);
  transition: all 150ms ease-in-out;
}

._view_raised__2Sv4j > div[class*="header"] div[class*="close-button"]:hover svg,
._view_smooth__2irKU > div[class*="header"] div[class*="close-button"]:hover svg {
  fill: var(--color60);
  transition: all 150ms ease-in-out;
}
._view_filled__Pnyfv > div[class*="header"] div[class*="close-button"]:hover svg {
  fill: var(--color5);
  transition: all 150ms ease-in-out;
}
._view_filled__Pnyfv > div[class*="header"] div[class*="close-button"]:hover,
._view_raised__2Sv4j > div[class*="header"] div[class*="close-button"]:hover,
._view_smooth__2irKU > div[class*="header"] div[class*="close-button"]:hover {
  cursor: pointer;
  transition: all 150ms ease-in-out;
}

/*BACKDROP STYLE*/
._view_backdrop-0__3L0I1 {
  background-color: rgba(25, 25, 29, 0);
}
._view_backdrop-10__1KQ4I {
  background-color: rgba(25, 25, 29, 0.1);
}
._view_backdrop-20__2pGQl {
  background-color: rgba(25, 25, 29, 0.2);
}
._view_backdrop-30__2iX_m {
  background-color: rgba(25, 25, 29, 0.3);
}
._view_backdrop-40__zQvc- {
  background-color: rgba(25, 25, 29, 0.4);
}
._view_backdrop-50__1hcW5 {
  background-color: rgba(25, 25, 29, 0.5);
}
._view_backdrop-60__at7G4 {
  background-color: rgba(25, 25, 29, 0.6);
}
._view_backdrop-70__3YaHO {
  background-color: rgba(25, 25, 29, 0.7);
}
._view_backdrop-80__3kB7b {
  background-color: rgba(25, 25, 29, 0.8);
}
._view_backdrop-90__FGkb8 {
  background-color: rgba(25, 25, 29, 0.9);
}
._view_backdrop-100__2PE0l {
  background-color: rgba(25, 25, 29, 1);
}
/* END STYLE */

/* BUTTONS TYPES*/
/*--DEFAULT--*/
._type_default__2RpV7 {
  font-size: var(--font_size_button);
  font-family: var(--font_family_button);
  font-weight: bold;
  text-align: center;
  letter-spacing: var(--letter-spacing-button);
  min-width: var(--s40);
  height: var(--s40);
  min-height: unset;
  padding: 0 1rem 0 1rem;
  line-height: var(--s16);
  border-radius: var(--s4);
  text-transform: capitalize;
}
._type_default__2RpV7._type_dense__2N8f- {
  font-weight: normal;
  letter-spacing: var(--letter_spacing_body_small);
  height: var(--s28);
  padding: 0 0.75rem 0 0.75rem;
}
/*--card--*/
._type_card__1wjPr {
  font-size: var(--font_size_caption);
  font-family: var(--font_family_button);
  line-height: var(--s16);
  text-align: center;
  letter-spacing: var(--letter_spacing_caption);
  text-transform: none;
  min-width: var(--s80);
  height: var(--s56);
  display: initial;
  word-break: break-all;
  padding: var(--s8) 0;
  border-radius: var(--s4);
  text-transform: capitalize;
}
._type_card__1wjPr p {
  margin: 0;
}
._type_card__1wjPr > span {
  margin-right: 0;
}
._type_card__1wjPr > span[class*="icon"] {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: -webkit-min-content;
  width: min-content;
  margin-bottom: 0.25rem;
}
._type_card__1wjPr[class*="button"] > div[class*="spinner"] {
  position: initial;
  margin-top: 0.625rem;
}
._type_dense__2N8f-._type_card__1wjPr {
  min-width: var(--s56);
  height: var(--s40);
  padding: var(--s6) 0;
  font-size: var(--font_size_caption_small);
  letter-spacing: var(--letter_spacing_caption_small);
}
._type_dense__2N8f-._type_card__1wjPr > span[class*="icon"] {
  margin-bottom: 0;
}
._type_dense__2N8f-._type_card__1wjPr[class*="button"] > div[class*="spinner"] {
  margin-top: 0.375rem;
}
/*--circle--*/
._type_circle__g9Omp {
  height: var(--s56);
  width: var(--s56);
  border-radius: 99px;
}

._type_dense__2N8f-._type_circle__g9Omp {
  height: var(--s40);
  width: var(--s40);
}
/*--ACTION--*/
._type_action__35vDq {
  font-family: var(--font_family_button);
  font-style: normal;
  font-weight: var(--font_weight_h6);
  font-size: var(--font_size_h6);
  letter-spacing: var(--letter_spacing_h6);
  height: var(--s56);
  border-radius: 99px;
  padding: 0.75rem 1rem;
  text-transform: capitalize;
}
._type_action__35vDq span {
  margin: 0;
}
._type_action__35vDq span:first-child[class*="icon"] {
  text-align: left;
  margin-right: 0.75rem;
}
._type_action__35vDq span:last-child[class*="icon"] {
  margin-left: 0.75rem;
}
._type_dense__2N8f-._type_action__35vDq {
  font-weight: normal;
  font-size: var(--font_size_body);
  letter-spacing: var(--letter_spacing_body_bold);
  height: var(--s40);
  border-radius: 99px;
  padding: 0.5rem 0.75rem;
}

._type_dense__2N8f-._type_action__35vDq span:first-child[class*="icon"] {
  margin-right: 0.625rem;
}
._type_dense__2N8f-._type_action__35vDq span:last-child[class*="icon"] {
  margin-left: 0.625rem;
}
/*--ICON--*/
._type_icon__3USJr {
  width: var(--s36);
  height: var(--s36);
  min-width: 0;
  min-height: 0;
  border-radius: 99px;
  padding: 0;
}
._type_dense__2N8f-._type_icon__3USJr {
  width: var(--s28);
  height: var(--s28);
}

/*FLAT STYLE*/
._view_flat__3aKbH,
._view_flat__3aKbH[class*="button"],
._view_flat__3aKbH:visited {
  color: var(--color70);
  background: none;
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._view_flat__3aKbH:hover,
._view_flat__3aKbH[class*="button"]:hover,
._view_flat__3aKbH:hover {
  box-shadow: var(--shadow2dp);
  background: none;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_flat__3aKbH:active,
._view_flat__3aKbH[class*="button"]:active,
._view_flat__3aKbH[class*="button"][class*="active"],
._view_flat__3aKbH[class*="button"]._view_focused__3x8Hl {
  background: var(--color5);
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_flat__3aKbH[class*="disabled"]:disabled,
._view_flat__3aKbH[class*="disabled"]:disabled > span > svg,
._view_flat__3aKbH[class*="disabled"]:disabled:hover,
._view_flat__3aKbH[class*="disabled"]:disabled:active {
  color: var(--color30);
  opacity: 1;
  background-color: var(--color0);
  box-shadow: none;
}
/*END STYLE*/

/* FILLED STYLE*/
._view_filled__178Xy[class*="button"],
._view_filled__178Xy[class*="button"]:visited {
  box-shadow: var(--shadow4dp);
  background: var(--color60);
  color: var(--color0);
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._view_filled__178Xy[class*="button"]:hover {
  background: var(--color70);
  box-shadow: var(--shadow8dp);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_filled__178Xy[class*="button"]:active,
._view_filled__178Xy[class*="button"][class*="active"]:active,
._view_filled__178Xy[class*="button"]._view_focused__3x8Hl {
  background: var(--color80);
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_filled__178Xy[class*="disabled"]:disabled {
  color: var(--color40);
  background-color: var(--color5);
  box-shadow: none;
  opacity: 1;
}
/* END STYLE */

/*SMOOTH STYLE*/
._view_smooth__215xQ[class*="button"],
._view_smooth__215xQ[class*="button"]:visited {
  color: var(--color70);
  background: var(--color5);
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._view_smooth__215xQ[class*="button"]:hover {
  color: var(--color100);
  background: var(--color10);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_smooth__215xQ[class*="button"]:active,
._view_smooth__215xQ[class*="button"]:active,
._view_smooth__215xQ[class*="button"][class*="active"],
._view_smooth__215xQ[class*="button"]._view_focused__3x8Hl {
  background: var(--color20);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_smooth__215xQ[class*="disabled"]:disabled,
._view_smooth__215xQ[class*="disabled"]:disabled:hover {
  color: var(--color30);
  background-color: var(--color0);
  opacity: 1;
}
/* END STYLE */

/* OUTLINED STYLE*/
._view_outlined__AXE0l[class*="button"],
._view_outlined__AXE0l[class*="button"]:visited {
  background: none;
  box-shadow: 0 0 0 1px var(--color30); /*border: 1px solid var(--color30);*/
  color: var(--color70);
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._view_outlined__AXE0l[class*="button"]:hover {
  box-shadow: 0 0 0 2px var(--color30);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_outlined__AXE0l[class*="button"]:active,
._view_outlined__AXE0l[class*="button"]._view_focused__3x8Hl {
  color: var(--color100);
  background-color: var(--color5);
  box-shadow: 0 0 0 2px var(--color50);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_outlined__AXE0l[class*="disabled"]:disabled {
  background-color: unset;
  color: var(--color30);
  box-shadow: 0 0 0 1px var(--color10);
}
/* END STYLE */

/* RAISED STYLE */
._view_raised__3pf9v[class*="button"],
._view_raised__3pf9v[class*="button"]:visited {
  background: var(--color0);
  color: var(--color70);
  box-shadow: var(--shadow4dp);
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._view_raised__3pf9v[class*="button"]:hover {
  background: var(--white);
  color: var(--color100);
  box-shadow: var(--shadow8dp);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_raised__3pf9v[class*="button"]:active,
._view_raised__3pf9v[class*="button"]._view_focused__3x8Hl {
  box-shadow: var(--shadow2dp);
  background-color: var(--white);
  color: var(--color70);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_raised__3pf9v[class*="disabled"]:disabled {
  color: var(--color40);
  background-color: var(--color5);
  box-shadow: none;
}
/* END STYLE */

/* BUTTONS TYPES*/

/*--DEFAULT--*/
._type_default__ZOdZ4 {
  text-transform: capitalize;
}

._type_default__ZOdZ4 > li::after {
  margin: 0 0.625rem;
  width: 1.25rem;
  height: 1.25rem;
}

._type_default__ZOdZ4 span[class*="icon"] {
  margin-right: 0.5rem;
}

._type_default__ZOdZ4 span[class*="breadcrumbs-collapsed"]::before {
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  margin-right: auto;
}
._type_default__ZOdZ4 span[class*="breadcrumbs-collapsed"] {
  margin-right: 0;
  width: 2rem;
  height: 1.25rem;
  border-radius: 0.25rem;
  padding: 0;
}
._type_default__ZOdZ4 a[class*="breadcrumb"] {
  padding: 0.125rem 0.5rem;
  border-radius: 0.25rem;
}

/*MORE ICON COLOR*/
._view_primary__-rtsV span[class*="breadcrumbs-collapsed"]::before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><g fill='%232979ff'><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>")
    center no-repeat;
}
._view_success__32pZW span[class*="breadcrumbs-collapsed"]::before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><g fill='%2343a047'><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>")
    center no-repeat;
}
._view_warning__118pi span[class*="breadcrumbs-collapsed"]::before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><g fill='%23d6981b'><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>")
    center no-repeat;
}
._view_danger__1sZX3 span[class*="breadcrumbs-collapsed"]::before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><g fill='%23F44336'><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>")
    center no-repeat;
}
._view_default__1Udh5 span[class*="breadcrumbs-collapsed"]::before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><g fill='%235A5B6A'><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>")
    center no-repeat;
}
._view_primary_alt__3gpZn span[class*="breadcrumbs-collapsed"]::before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><g fill='%235c6bc0'><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>")
    center no-repeat;
}
._view_success_alt__1QGsp span[class*="breadcrumbs-collapsed"]::before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><g fill='%23069697'><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>")
    center no-repeat;
}
._view_warning_alt__1-vkA span[class*="breadcrumbs-collapsed"]::before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><g fill='%23fb8200'><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>")
    center no-repeat;
}
._view_danger_alt__1Y7DN span[class*="breadcrumbs-collapsed"]::before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><g fill='%23ec407a'><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>")
    center no-repeat;
}
/*CHEVRON ICON COLOR*/
._view_primary__-rtsV > li::after {
  background: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M13.71 9.29L7.71 3.29C7.53 3.11 7.28 3 7 3C6.45 3 6 3.45 6 4C6 4.28 6.11 4.53 6.29 4.71L11.59 10L6.3 15.29C6.11 15.47 6 15.72 6 16C6 16.55 6.45 17 7 17C7.28 17 7.53 16.89 7.71 16.71L13.71 10.71C13.89 10.53 14 10.28 14 10C14 9.72 13.89 9.47 13.71 9.29Z' fill='%232979FF'/></svg>");
}
._view_success__32pZW > li::after {
  background: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M13.71 9.29L7.71 3.29C7.53 3.11 7.28 3 7 3C6.45 3 6 3.45 6 4C6 4.28 6.11 4.53 6.29 4.71L11.59 10L6.3 15.29C6.11 15.47 6 15.72 6 16C6 16.55 6.45 17 7 17C7.28 17 7.53 16.89 7.71 16.71L13.71 10.71C13.89 10.53 14 10.28 14 10C14 9.72 13.89 9.47 13.71 9.29Z' fill='%2343a047'/></svg>");
}
._view_warning__118pi > li::after {
  background: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M13.71 9.29L7.71 3.29C7.53 3.11 7.28 3 7 3C6.45 3 6 3.45 6 4C6 4.28 6.11 4.53 6.29 4.71L11.59 10L6.3 15.29C6.11 15.47 6 15.72 6 16C6 16.55 6.45 17 7 17C7.28 17 7.53 16.89 7.71 16.71L13.71 10.71C13.89 10.53 14 10.28 14 10C14 9.72 13.89 9.47 13.71 9.29Z' fill='%23d6981b'/></svg>");
}
._view_danger__1sZX3 > li::after {
  background: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M13.71 9.29L7.71 3.29C7.53 3.11 7.28 3 7 3C6.45 3 6 3.45 6 4C6 4.28 6.11 4.53 6.29 4.71L11.59 10L6.3 15.29C6.11 15.47 6 15.72 6 16C6 16.55 6.45 17 7 17C7.28 17 7.53 16.89 7.71 16.71L13.71 10.71C13.89 10.53 14 10.28 14 10C14 9.72 13.89 9.47 13.71 9.29Z' fill='%23F44336'/></svg>");
}
._view_default__1Udh5 > li::after {
  background: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M13.71 9.29L7.71 3.29C7.53 3.11 7.28 3 7 3C6.45 3 6 3.45 6 4C6 4.28 6.11 4.53 6.29 4.71L11.59 10L6.3 15.29C6.11 15.47 6 15.72 6 16C6 16.55 6.45 17 7 17C7.28 17 7.53 16.89 7.71 16.71L13.71 10.71C13.89 10.53 14 10.28 14 10C14 9.72 13.89 9.47 13.71 9.29Z' fill='%235a5b6a'/></svg>");
}
._view_primary_alt__3gpZn > li::after {
  background: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M13.71 9.29L7.71 3.29C7.53 3.11 7.28 3 7 3C6.45 3 6 3.45 6 4C6 4.28 6.11 4.53 6.29 4.71L11.59 10L6.3 15.29C6.11 15.47 6 15.72 6 16C6 16.55 6.45 17 7 17C7.28 17 7.53 16.89 7.71 16.71L13.71 10.71C13.89 10.53 14 10.28 14 10C14 9.72 13.89 9.47 13.71 9.29Z' fill='%235c6bc0'/></svg>");
}
._view_success_alt__1QGsp > li::after {
  background: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M13.71 9.29L7.71 3.29C7.53 3.11 7.28 3 7 3C6.45 3 6 3.45 6 4C6 4.28 6.11 4.53 6.29 4.71L11.59 10L6.3 15.29C6.11 15.47 6 15.72 6 16C6 16.55 6.45 17 7 17C7.28 17 7.53 16.89 7.71 16.71L13.71 10.71C13.89 10.53 14 10.28 14 10C14 9.72 13.89 9.47 13.71 9.29Z' fill='%23069697'/></svg>");
}
._view_warning_alt__1-vkA > li::after {
  background: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M13.71 9.29L7.71 3.29C7.53 3.11 7.28 3 7 3C6.45 3 6 3.45 6 4C6 4.28 6.11 4.53 6.29 4.71L11.59 10L6.3 15.29C6.11 15.47 6 15.72 6 16C6 16.55 6.45 17 7 17C7.28 17 7.53 16.89 7.71 16.71L13.71 10.71C13.89 10.53 14 10.28 14 10C14 9.72 13.89 9.47 13.71 9.29Z' fill='%23fb8200'/></svg>");
}
._view_danger_alt__1Y7DN > li::after {
  background: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M13.71 9.29L7.71 3.29C7.53 3.11 7.28 3 7 3C6.45 3 6 3.45 6 4C6 4.28 6.11 4.53 6.29 4.71L11.59 10L6.3 15.29C6.11 15.47 6 15.72 6 16C6 16.55 6.45 17 7 17C7.28 17 7.53 16.89 7.71 16.71L13.71 10.71C13.89 10.53 14 10.28 14 10C14 9.72 13.89 9.47 13.71 9.29Z' fill='%23ec407a'/></svg>");
}
/*--------*/

/*SMOOTH STYLE*/
._view_smooth__2lsdn {
  color: var(--color100);
}
._view_smooth__2lsdn span[class*="breadcrumbs-collapsed"] {
  background: var(--color30);
  color: var(--color100);
}
._view_smooth__2lsdn a[class*="breadcrumb"] {
  color: var(--color80);
}
._view_smooth__2lsdn a[class*="breadcrumb"] > span[class*="icon"] {
  color: var(--color30);
}
._view_smooth__2lsdn a[class*="breadcrumb"]:hover {
  background: var(--color5);
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_smooth__2lsdn a[class*="breadcrumb"]:hover > span[class*="icon"] {
  color: var(--color50);
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}

/* END STYLE */
._view_popover__et3kh div[class*="popover-content"] a[class*="menu-item"]:hover {
  background-color: var(--color5);
}
._view_popover__et3kh div[class*="popover-arrow"] {
  display: none;
}

.styles_container__26SxD {
  display: grid;
  grid-template-areas:
    "header "
    "content";
  grid-template-columns: fit-content(100%);
  padding-top: 1em;
  padding-left: 20px;
  padding-right: 20px;

}

.styles_breadcrumbs__hGXxk {
  grid-area: header;
  top: 4.5rem;

}

.styles_content__3xRVe {
  grid-area: content;
  padding-top: 2.5em;

}

.styles_form__3kKkq {
  display: flex;
  flex-direction: column;
  max-width: 700px;
  margin: auto;
  text-align: center;
}

.styles_button__2TvkU {
  color: #61dafb;
}

.styles_icon__24xb7 {
  justify-content: left;
  background: #00a6e9;
  text-align: left;
  max-width: 180px;
}

.styles_textField__259bY {
  position: relative;
  float: left;
  width: '100%';
  margin-right: 30px;
}
