/*MENU SHAPE CSS MODULE*/
/*DEFAULT*/
.def,
.def div[class*="popover-content"] > ul[class*="menu"] {
  border-radius: 0.5rem;
  margin: 0;
  padding: 0;
  font-size: var(--font_size_body);
  letter-spacing: var(--letter_spacing_body);
}
.def div[class*="popover-content"] {
  border-radius: 0.5rem;
}
.def > li:not([class*="menu-divider"]),
.def
  div[class*="popover-content"]
  > ul[class*="menu"]
  > li:not([class*="menu-divider"]) {
  height: 2.5rem;
  margin: 0;
}
.def > li:first-of-type,
.def div[class*="popover-content"] > ul[class*="menu"] > li:first-of-type {
  padding-top: 0.5rem;
  line-height: 3rem;
  height: 3rem;
}
.def > li:last-of-type,
.def div[class*="popover-content"] > ul[class*="menu"] > li:last-of-type {
  padding-bottom: 0.5rem;
  height: 3rem;
}
.def div[class*="popover"][class*="submenu"],
.dense div[class*="popover"][class*="submenu"] {
  padding: 0;
  margin-left: -0.0625rem;
}
.def div[class*="popover"][class*="submenu"],
.dense div[class*="popover"][class*="submenu"] {
  animation: slide-fwd-right 0.28s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/*Heading divider*/
.def > li[class*="menu-header"]:not([class*="menu-divider"]),
.dense > li[class*="menu-header"]:not([class*="menu-divider"]) {
  margin-top: 0.5rem;
}
/*----*/

/*DENSE*/
.dense,
.dense div[class*="popover-content"] > ul[class*="menu"] {
  border-radius: 0.375rem;
  margin: 0;
  padding: 0;
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
}

.dense div[class*="popover-content"] {
  border-radius: 0.375rem;
}
.dense > li:not([class*="menu-divider"]),
.dense
  div[class*="popover-content"]
  > ul[class*="menu"]
  > li:not([class*="menu-divider"]) {
  height: 1.75rem;
  margin: 0;
}
.dense > li:first-of-type,
.dense div[class*="popover-content"] > ul[class*="menu"] > li:first-of-type {
  padding-top: 0.5rem;
  line-height: 2.25rem;
  height: 2.25rem;
}
.dense > li:last-of-type,
.dense div[class*="popover-content"] > ul[class*="menu"] > li:last-of-type {
  padding-bottom: 0.5rem;
  height: 2.25rem;
}

/*-------------------*/

@keyframes slide-fwd-right {
  0% {
    transform: scale(0.9) translateX(-20%);
  }
  100% {
    transform: scale(1) translateX(0);
  }
}
