@import '../../styles/colors.css';
@import '../../styles/fonts.css';

.container {
  padding-right: 1rem;
  padding-left: 1rem;
  font-family: var(--Acumin)
}

.sub_title {
  padding-top: 1rem;
}

.h4_title {

  padding-right: 5rem;
  font-family: var(--Acumin);
}

.h5_title {

  padding-right: 5rem;
  font-family: var(--Acumin);

}

.text {
  padding-top: 0.5rem;
  padding-right: 5rem;
  font-family: var(--Acumin);
}

.link,
.link:active {
  color: var(--blue60);
  margin: 0 0.25rem;
  text-decoration: underline;
}

.link:hover {
  color: var(--blue70);
}

.promo_group {
  display: flex;
  flex-direction: column;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.promo_item {
  width: 100%;
  padding-bottom: 1.5rem;
}

.item_img_container {
  border-radius: 0.5rem;
  padding: 0.25rem;

}

.item_logo {
  padding-right: 5rem;
  width: 25%;

}

.item_img {
  padding-right: 5rem;
  width: 100%;

}

@media screen and (min-width: 640px) {
  .promo_group {
    display: flex;
    flex-direction: initial;
    justify-content: space-between;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .promo_item {
    width: calc(25% - 0.75rem);
    padding-bottom: 1.5rem;
  }
}