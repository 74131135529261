.container {
  margin: 2.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cards_row,
.cards_row_last {
  display: flex;
  flex: 1 1 auto;
  margin: 1rem;
}
.cards_row_last {
  justify-content: space-between;
}
.cards_row_last > div:not(:last-child) {
  margin-right: 1rem;
}

.item__icon {
  color: var(--color40);
  justify-content: flex-start;
  display: flex;
  margin-bottom: 1rem;
}
.card_row_add {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* max-width: 290px; */
  margin: 1rem 3rem;
}
.card_row_add > div {
  margin-bottom: 0.5rem;
}
.dialog div[class*="footer"] {
  flex-direction: column;
}
.dialog div[class*="footer"] button {
  width: 100%;
}
.dialog div[class*="right-button"] {
  margin-top: 1.5rem;
}

@media (min-width: 640px) {
  .cards_row > div:not(:last-child) {
    margin-right: 1rem;
  }
}
