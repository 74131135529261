/**TOAST*/
.def > div {
  height: auto;
  border-radius: 0.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  z-index: 999;
}
.def span[class*="toast-message"] {
  padding: 0;
  margin: auto;
  margin-left: 1rem;
  font-size: var(--font_size_h6);
  font-family: var(--font_secondary);
  letter-spacing: var(--letter_spacing_h6);
  font-weight: bold;
}
.def div[class*="button-group"] {
  padding: 0;
  margin: auto;
  margin-right: 1rem;
}
.def div[class*="button-group"] svg {
  width: 1.5rem;
  height: 1.5rem;
}
.def div[class*="button-group"] a {
  margin-left: 0.5rem;
  font-family: var(--font_primary);
  text-transform: uppercase;
  font-size: var(--font_size_button);
  letter-spacing: var(--letter_spacing_button);
  font-weight: var(--font_weight_button);
}

.def span[class*="icon"]:first-child {
  float: left;
  margin: auto;
  border-radius: 99px;
  margin-left: 1rem;
  width: 2.5rem;
  height: 2.5rem;
}
.def span[class*="icon"]:first-child > svg {
  margin: auto;
  margin-top: 0.5rem;
}

.def.no-close div[class*="button-group"] button[class*="button"] {
  display: none;
}
