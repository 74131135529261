/*FLAT STYLE*/
.flat,
.flat[class*="button"],
.flat:visited {
  color: var(--color70);
  background: none;
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
.flat:hover,
.flat[class*="button"]:hover,
.flat:hover {
  box-shadow: var(--shadow2dp);
  background: none;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.flat:active,
.flat[class*="button"]:active,
.flat[class*="button"][class*="active"],
.flat[class*="button"].focused {
  background: var(--color5);
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.flat[class*="disabled"]:disabled,
.flat[class*="disabled"]:disabled > span > svg,
.flat[class*="disabled"]:disabled:hover,
.flat[class*="disabled"]:disabled:active {
  color: var(--color30);
  opacity: 1;
  background-color: var(--color0);
  box-shadow: none;
}
/*END STYLE*/

/* FILLED STYLE*/
.filled[class*="button"],
.filled[class*="button"]:visited {
  box-shadow: var(--shadow4dp);
  background: var(--color60);
  color: var(--color0);
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
.filled[class*="button"]:hover {
  background: var(--color70);
  box-shadow: var(--shadow8dp);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.filled[class*="button"]:active,
.filled[class*="button"][class*="active"]:active,
.filled[class*="button"].focused {
  background: var(--color80);
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.filled[class*="disabled"]:disabled {
  color: var(--color40);
  background-color: var(--color5);
  box-shadow: none;
  opacity: 1;
}
/* END STYLE */

/*SMOOTH STYLE*/
.smooth[class*="button"],
.smooth[class*="button"]:visited {
  color: var(--color70);
  background: var(--color5);
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
.smooth[class*="button"]:hover {
  color: var(--color100);
  background: var(--color10);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.smooth[class*="button"]:active,
.smooth[class*="button"]:active,
.smooth[class*="button"][class*="active"],
.smooth[class*="button"].focused {
  background: var(--color20);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.smooth[class*="disabled"]:disabled,
.smooth[class*="disabled"]:disabled:hover {
  color: var(--color30);
  background-color: var(--color0);
  opacity: 1;
}
/* END STYLE */

/* OUTLINED STYLE*/
.outlined[class*="button"],
.outlined[class*="button"]:visited {
  background: none;
  box-shadow: 0 0 0 1px var(--color30); /*border: 1px solid var(--color30);*/
  color: var(--color70);
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
.outlined[class*="button"]:hover {
  box-shadow: 0 0 0 2px var(--color30);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.outlined[class*="button"]:active,
.outlined[class*="button"].focused {
  color: var(--color100);
  background-color: var(--color5);
  box-shadow: 0 0 0 2px var(--color50);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.outlined[class*="disabled"]:disabled {
  background-color: unset;
  color: var(--color30);
  box-shadow: 0 0 0 1px var(--color10);
}
/* END STYLE */

/* RAISED STYLE */
.raised[class*="button"],
.raised[class*="button"]:visited {
  background: var(--color0);
  color: var(--color70);
  box-shadow: var(--shadow4dp);
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
.raised[class*="button"]:hover {
  background: var(--white);
  color: var(--color100);
  box-shadow: var(--shadow8dp);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.raised[class*="button"]:active,
.raised[class*="button"].focused {
  box-shadow: var(--shadow2dp);
  background-color: var(--white);
  color: var(--color70);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.raised[class*="disabled"]:disabled {
  color: var(--color40);
  background-color: var(--color5);
  box-shadow: none;
}
/* END STYLE */
