.layout {
  display: flex;
  background: transparent;
  border: 1px solid var(--grey20);
  border-radius: 0.5rem;
  padding: 1rem;
  margin: 1rem;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.grid_item {
  display: flex;
  flex-direction: column;
  /* padding: 40px 0; */
  align-items: center;
  border-radius: 1rem;
  background: transparent;
}
.grid_item:hover {
  cursor: pointer;
  background: var(--color10);
  transition: all 0.25s ease-in-out;
}

.item__icon {
  width: 6.5em;
  height: 6.5em;
  background: var(--color50);
  border-radius: 1rem;
  color: white;
  justify-content: center;
  display: flex;
  align-items: center;
}
.item__title {
  text-align: center;
  margin-top: 1.5rem;
  white-space: pre-wrap;
}

@media (min-width: 540px) {
  .layout {
    justify-content: center;
  }
}
