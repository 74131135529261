/* TYPOGRAPHY TYPES*/

.roboto {
  font-family: var(--roboto);
}
.inter {
  font-family: var(--inter);
}
.lato {
  font-family: var(--lato);
}
.plex {
  font-family: var(--plex);
}
.manrope {
  font-family: var(--manrope);
}

.h1 {
  font-weight: var(--font_weight_h1);
  font-size: var(--font_size_h1);
  letter-spacing: var(--letter_spacing_h1);
  line-height: 120%;
}

.h2 {
  font-weight: var(--font_weight_h2);
  font-size: var(--font_size_h2);
  letter-spacing: var(--letter_spacing_h2);
  line-height: 120%;
}

.h3 {
  font-weight: var(--font_weight_h3);
  font-size: var(--font_size_h3);
  letter-spacing: var(--letter_spacing_h3);
  line-height: 124%;
}

.h4 {
  font-weight: var(--font_weight_h4);
  font-size: var(--font_size_h4);
  letter-spacing: var(--letter_spacing_h4);
  line-height: 128%;
}

.h5 {
  font-weight: var(--font_weight_h5);
  font-size: var(--font_size_h5);
  letter-spacing: var(--letter_spacing_h5);
  line-height: 150%;
}

.h6 {
  font-weight: var(--font_weight_h6);
  font-size: var(--font_size_h6);
  letter-spacing: var(--letter_spacing_h6);
  line-height: 150%;
}

.body {
  font-weight: var(--font_weight_body);
  font-size: var(--font_size_body);
  letter-spacing: var(--letter_spacing_body);
  line-height: 150%;
}
.body_bold {
  font-weight: var(--font_weight_body_medium);
  letter-spacing: var(--letter_spacing_body_bold);
}
.body.large {
  font-size: var(--font_size_body_large);
  letter-spacing: var(--letter_spacing_body_large);
  line-height: 158%;
}
.body_bold.large {
  font-size: var(--font_size_body_large);
  letter-spacing: var(--letter_spacing_body_large_bold);
  line-height: 158%;
}
.body.small,
.body_bold.small {
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
  line-height: 143%;
}
.button_text {
  font-weight: var(--font_weight_button);
  font-size: var(--font_size_button);
  letter-spacing: var(--letter_spacing_button);
  line-height: 114%;
}

.caption {
  font-weight: var(--font_weight_caption);
  font-size: var(--font_size_caption);
  letter-spacing: var(--letter_spacing_caption);
  line-height: 126%;
}
.caption.small {
  font-size: var(--font_size_caption_small);
  font-weight: var(--font_weight_caption_small);
  letter-spacing: var(--letter_spacing_caption_small);
  line-height: 120%;
}

.overline {
  font-weight: var(--font_weight_overline);
  font-size: var(--font_size_overline);
  letter-spacing: var(--letter_spacing_overline);
  text-transform: uppercase;
  line-height: 126%;
}
.overline.small {
  font-size: var(--font_size_overline_small);
  letter-spacing: var(--letter_spacing_overline_small);
  line-height: 120%;
}
