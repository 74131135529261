/*MENU DIVIDER SHAPE CSS MODULE*/

.def,
.dense {
  margin: 0.5rem;
  padding: 0;
}
.def > h6 {
  line-height: 2.5rem;
  padding: 0;
  margin-left: 0.5rem;
}

.dense > h6 {
  line-height: 1.75rem;
  padding: 0;
  margin-left: 0.5rem;
}
