.container {
  display: grid;
  grid-template-areas:
    "header "
    "content";
  grid-template-columns: fit-content(100%);
  padding-top: 1em;
  padding-left: 20px;
  padding-right: 20px;

}

.breadcrumbs {
  grid-area: header;
  top: 4.5rem;

}

.content {
  grid-area: content;
  padding-top: 2.5em;

}

.form {
  display: flex;
  flex-direction: column;
  max-width: 700px;
  margin: auto;
  text-align: center;
}

.button {
  color: #61dafb;
}

.icon {
  justify-content: left;
  background: #00a6e9;
  text-align: left;
  max-width: 180px;
}

.textField {
  position: relative;
  float: left;
  width: '100%';
  margin-right: 30px;
}