@import '../../styles/colors.css';

.footerBar {
  padding: 20px 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.text {
  font-weight: 700;
  color: var(--color-footer-text);
}

.contactLinks {
  text-align: right;
  bottom: 10px;
}

.contactLinks>a {
  cursor: pointer;
}

.contactLinks>a+a {
  margin-left: 35px;

}

.contact>p {
  font-weight: 400;
  margin-bottom: 0;
}

.contact>p>a {
  cursor: pointer;
  margin-left: 10px;
  bottom: 0;
}

@media only screen and (max-width: 600px) {
  .footerBar {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  .contactLinks {
    text-align: center;
  }
  .contactLinks + p {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}