/* TREE TYPES*/

.def li[class*="tree-node"] {
  font-size: var(--font_size_body);
  letter-spacing: var(--letter_spacing_body);
  font-weight: var(--font_weight_body);
  transition: all 100ms ease-in-out;
}
.dense li[class*="tree-node"] {
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
  font-weight: var(--font_weight_body);
  transition: all 100ms ease-in-out;
}

.def li[class*="tree-node-expanded"],
.dense li[class*="tree-node-expanded"] {
  font-weight: var(--font_weight_body_medium);
  transition: all 150ms ease-in;
}

.def li[class*="selected"],
.dense li[class*="selected"] {
  font-weight: var(--font_weight_body_medium);
  transition: all 150ms ease-in;
}

.def li > div[class*="node-content"] {
  min-height: 2.5rem;
  height: unset;
  margin-bottom: 0.25rem;
  box-shadow: none;
  border-radius: 0.25rem;
  transition: all 200ms ease-in-out;
}
.dense li > div[class*="node-content"] {
  min-height: 1.75rem;
  height: unset;
  margin-bottom: 0.125rem;
  box-shadow: none;
  border-radius: 0.125rem;
  transition: all 200ms ease-in-out;
}

.def
  li
  > div[class*="node-content"]
  > span[class*="node-caret"]:not([class*="node-caret-none"]) {
  width: 2rem;
  height: 2rem;
  min-width: unset;
  margin-left: 0.25rem;
  margin-right: 0.5rem;
  border-radius: 100%;
  padding: 0;
}

.dense li > div[class*="node-content"] > span[class*="node-caret"] {
  /*width: 1.25rem;
  height: 1.25rem;*/
  min-width: unset;
  margin-left: 0.375rem;
  margin-right: 0.5rem;
  padding: 0;
}
.def li > div[class*="node-content"] > span[class*="node-caret"] > svg,
.def span[class*="node-caret-none"] ~ span[class*="node-icon"] > svg {
  width: 1.25rem;
  height: 1.25rem;
  margin: auto;
}
.def li > div[class*="node-content"] > span[class*="node-caret"] > svg {
  margin-top: 0.375rem;
}
.def span[class*="node-caret-none"] {
  min-width: 0.625rem;
  margin-left: 0.625rem;
}
.dense span[class*="node-caret-none"] {
  margin-left: 0 !important;
}

.def div[with-secondary-label] {
  height: 2.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.def div[with-secondary-label] > div {
  /* font-size: var(--font-size-Body2);
  letter-spacing: var(--letter-spacing-Body2);*/
  margin-top: 0.125rem;
}

.def li[class*="disabled"] > div > span > div[with-secondary-label] > div {
  /* font-size: var(--font-size-Body2);
  letter-spacing: var(--letter-spacing-Body2);*/
}

.def
  li[class*="disabled"]
  > div
  > span
  > div[with-secondary-label]
  > div
  > span {
  display: inline-block;
}

/*Secondary icon*/
.def span[class*="node-secondary-label"] > span[class*="icon"] > svg {
  width: 1.25rem;
  height: 1.25rem;
}
/*----*/
