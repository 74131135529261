@import '../../styles/colors.css';
.container {
  display: grid;
  grid-template-areas:
    "header header "
    "sidebar content "
    "footer footer";
  grid-template-columns: fit-content(100%);
}

.navbar {
  grid-area: header;
  background: var(--color-header-background);
}

.footer {
  padding: 12px 20px;
  display: flex;
  height: 10vh;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  left: 0;
}

.content {
  top: 4.5rem;
  height: 80vh;
  grid-area: content;
  margin-right: 0;
  margin-left: 0;
  width: 150vh;
  padding: 0 1rem;

}

.left_sidebar {
  /*display: grid;*/
  width: 180px;
  grid-area: sidebar;
  height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
  position: fixed;
  top: 4.5rem;
  left: 0;
}


.sidebar {
  grid-area: sidebar;
  height: fit-content;
  width: 240px;
  z-index: 5;
  display: block;
  transform: translateX(-100%);
  transition: all 250ms ease-in-out;
  display: contents;
  /*overflow-y: auto;*/
  background: var(--grey0);
  border-radius: 0 0.5rem 0.5rem 0;
}



.root {
  flex-grow: 1,
}

.button {
  float: "right"
}

.title {
  flex-grow: 1,
}


.main-content {
  display: inline-block;
  width: 100%;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
  list-style-position: outside;
}

.blink {
  animation: blink-animation 3s steps(25, start) infinite;
}
button:focus {
  outline: 0;
}